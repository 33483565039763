.no-products-found, .no-search-found {
  text-align: center;
  margin: 9vw 0 10vw;
  padding: 56px;
    @include breakpoint(small down) {   
    margin: 56px 0 72px;
    padding: 32px;
    }
}

.layout-options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;


  @media screen and (max-width: 63.9375em) {
    //justify-content: start;
    padding-top: 5px;
  }

    span {
      @include inter-bold;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.05em;
      margin: 3px 10px 0 0;
    }
}

.h1-tag {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  font-family: 'inter-regular',Arial,Helvetica,sans-serif;
}

.filters-row {
  display: flex;
  @media screen and (max-width: 63.9375em) {
    flex-direction: column;
  }
}

.current-filters {
  margin: 0 3px;
}

.current-filter-name {
  @include inter-semibold;
  display: inline-block;
}

.current-filter, .remove-all {
  @include inter-medium;
  display: inline-block;
  width: max-content;
  font-size: 12px;
  position: relative;
  color: $dark-grey;
  background-color: $light-grey;
  border-radius: 20px;
  padding: 4px 24px 4px 8px;
  margin: 0 8px;

  @include breakpoint(medium down) {
    @include mb-8;
  }

  img {
    width: 10px;
    position: absolute;
    top: 8px;
    right: 0;
    margin: 0 8px;
  }

  &.no-background {
    background: none;
    border: 1px solid $light-grey;
  }
}

.remove-all {
  font-size: 12px;

  img {
    top: 8px;
  }
}

.remove-current-filter {
  display: block;
  border: 1px solid $gold;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 10px 3px;
  margin: 10px 20px 0 0;
  @include inter-medium;
}

.promo-tag {
  @include inter-bold;
  position: absolute;
  right: 0;
  top: 0;
  background-color: $black;
  color: $white;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.1em;
  padding: 5px 20px 4px;
  z-index: 10;
}

@keyframes indeterminate-bar {
  0% {
    transform: translate(-50%) scaleX(0);
  }
  50% {
    transform: translate(0%) scaleX(0.3);
  }
  100% {
    transform: translate(50%) scaleX(0);
  }
}

#arrow-filter {
  transition: 1s;
}

.rotated {
  transform: rotate(180deg);
}

//ordina per
.radio-layout {
  display: flex;
  padding: 8px;

  input {
    position: absolute;
    display: none;
  }

  .layout-grid-img:not(.active) {
    display: block;
  }
  .layout-grid-img.active {
    display: none;
  }

  input[type=radio]:checked + label {
    .layout-grid-img:not(.active) {
      display: none;
    }
    .layout-grid-img.active {
      display: block;
    }
  }

  label {
    margin: 0;
    padding: 0;
    font-size: 0;
    display: block;
  }
}

#catalog-container {

  .catalog-spinner {
    display: none;
    z-index: 10000;
    position: fixed;
    top: 150px;
    width: 100%;
    height: 3px;

    @include breakpoint(small down) {
      top: 0;
    }

    &:after {
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-animation: indeterminate-bar 2s linear infinite;
      animation: indeterminate-bar 2s linear infinite;
      width: 100%;
      height: 2px;
      background-color: $secondary-color;
      content: '';
      display: block;
    }
  }

  &.catalog-loading {
    .catalog-spinner {
      display: block;
    }

    &:before {
      content: '';
      display: block;
      position: fixed;
      z-index: 10000;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(white, 0.5);
    }
  }
}

.visible-active{
  display: none !important;
}
.hidden-active{
  display: inline;
}

.contact-no-quantity {
  width: 100%;
  font-size: 15px;
  margin-top: 20px;
}

li.active > a {
  /* background: white;
  color:#fff; */
  .visible-active{
    display: inline !important;
  }
  .hidden-active{
    display: none !important;
  }
}

.filters {

  flex-wrap: wrap;

  .filter-tag {

    padding: 0 16px 16px 0;
    position: relative;
    @include breakpoint(small down) {
      padding: 8px;
    }

    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      min-width: 33%;
    }

    .scroll-view {
        height: 260px
      }

      > ul {
        li > a > span{
          text-transform: capitalize;
          display: block;
          padding: 4px 10px;
          color: black;
          background: white;
          margin: 2px 0;

          &:hover {
            background: darken(grey, 5%) !important;
            color:#fff;
            text-decoration: none;
          }

          .visible-active{
            display: none;
          }
          .hidden-active{
            display: inline;
          }
        }

        li.active > a {
          /* background: white; */
          /* color:#fff; */
          .visible-active{
            display: inline;
          }
          .hidden-active{
            display: none;
          }
        }
      }
    }

    .filter-sublist {
      > li > a {
        padding-left: 12px;
        @include inter-medium;
        text-transform: initial;
      }

      > li.active > .toggle-subfilter {
        color: white;
      }

      > li > .toggle-subfilter {
        position: absolute;
        z-index: 2;
        display: block;
        width: 16px;
        height: 16px;
        text-align: center;
        .fa-plus{
          display:inline-block;
        }
        .fa-minus{
          display:none;
        }
      }

      > li.open > .toggle-subfilter {
        .fa-plus{
          display:none;
        }
        .fa-minus{
          display:inline-block;
        }
      }

      > li > .toggle-subfilter + a {
        padding-left: 24px;
      }

        > li {
          text-transform: capitalize;
        }

      > li > ul {
        display: none;
      }

      > li.open > ul {
        display: block;
      }
    }

    .filter-name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: capitalize;
      font-size: 16px;
      line-height: 24px;
      padding: 0 16px;
      margin: 0;
      background: $light-grey;
      border: 1px solid $light-grey;
      cursor: pointer;
      width: fit-content;
      @include inter-regular;
      width: 100%;
      border-radius: 3px;
      height: 40px;

    }
  
    &.open{
      .filter-name{
        border: 1px solid $black !important;
        span.fa{
          filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
          transform: rotate(-180deg);
        }
      }
    }
  
    .filter-dropdown{
      display:none;
      position: absolute;
      top: 50px;
      z-index: 1;
      background: $white;
      padding: 0px 8px 8px 8px;
      border: 1px solid $grey-2  ;
      z-index: 10;
      width: 95%;
      @include mt-8;

      @media screen and (max-width: 63.9375em) {
        top: auto;
      }
  
      .btn{
        display: block;
        margin-top: 5px;
        background-color: $dark-grey;
      }
    }
}

#mobile-filters-panel, #mobile-sort-options-panel {
  .panel {
    width: 300px;
    height: 100%;
    overflow-y: scroll;
    color: $white;
    background-color: $black;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-300px);
    z-index: $header-z + 2;
    transition: all 0.4s ease-in-out;

    * { color: inherit };

    .remove-all-row {
      margin-bottom: -16px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .remove-all {
      color: $black;
      font-size: 12px;
      margin-right: 16px;
    }

    &.active-filters {
      @include pt-16;
    }

    .filter {
      margin: 16px;
      padding-bottom: 8px;
      border-bottom: 1px solid $white;

      .filter-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-transform: uppercase;
        font-size: 20px;

        .number-select {
          font-size: 14px;
        }

        .arrow {
          width: 10px;
        }
      }
    }

    .option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin: 0 16px;
      border-bottom: 1px solid $white;

      a {
        display: block;
        padding: 16px 0;
        width: 100%;
        font-size: 20px;
        text-transform: uppercase;
      }

      .fa {
        display: none;
        margin-right: 16px;
      }

      &.selected {
        .fa {
          display: block;
        }
      }
    }

    .show-all {
      cursor: pointer;
      text-transform: uppercase;
      color: $gold;
      font-size: 14px;
      text-align: right;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($white, 0.5);
    z-index: $header-z + 1;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .2s, opacity .2s linear;
  }

  &.open {
    .panel {
      transform: translateX(0);
    }

    .overlay {
      visibility: visible;
      opacity: 1;
      transition: opacity .2s linear;
    }
  }
}

#mobile-filters-panel {
  .sublist .items {
    display: none;
  }

  .sublist.open .items {
    display: block;
  }

  .filter-list {
    display: none;
  }

  .filter.open {
    .filter-title .arrow {
      transform: rotate(180deg);
    }

    .filter-list {
      display: block;
    }
  }

  .toggle-subfilter {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    a {
      text-transform: uppercase;
    }
  }

  .catalog-filter {
    margin-left: 8px;

    span {
      margin-left: 4px;
    }
  }

  .fa-plus, .fa-minus {
    margin-right: 8px;
  }

  .fa-plus {
    display: inline-block;
  }
  .fa-minus {
    display: none;
  }

  li.open {
    .fa-plus {
      display: none;
    }
    .fa-minus {
      display: inline-block;
    }
  }
}

.border-catalog {
  border-bottom: 1px solid $grey-2;
  @include pb-16;

  @include breakpoint (small down) {
    padding: 0;
  }
}

li.active-original {
  @include pb-8;
}


//catalog banner catalog
.catalog-banner-img {
  height: 250px;
}

.layout-grid-img {
  width: 32px;

  @include breakpoint (small down) {
    width: 40px;
  }
}

.catalog-toggle {
  width: 24px;
  margin: 8px;
}

#toggle-filter {
  position: relative;
}

#toggle-filter.active::after {
  content: "";
  width: 10px;
  height: 10px;
  background: $gold;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 4px;
}

.filter-mobile-label {
    @include inter-semibold;
    margin-bottom: 0;
    color: $dark-grey;
}

.number-select {
  @include inter-medium;
  padding: 7px 0px;
  color: $grey-2;
  font-size: 14px;
}

.fa-check:before {
  content: "\f00c";
  color: $white;
  text-shadow: 0px 0px 1px $dark-grey;
}

.img-search-error {
  width: 31px;
  margin: 9px 20px;
}


//pagination
.pagination li{
  @include breakpoint(small down) {
    display: inline-block;
  }
}

ul.pagination a {
  @include breakpoint(small down){
    width: 31px;
    height: 31px;
  }
}


.row-filter{
  justify-content:space-between;
  
  @include breakpoint(medium down) {
    margin-top:15px;
  }
  
}

.search-container {
  background: $light-grey !important;
  width: 100%;
  padding: 1px 10px;
  border-radius: 3px;
  display: flex;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
}

.catalog-search {
  margin: 0;
  border: none !important;
  height: 32px;
  background: $light-grey !important;
  padding: 4px 0 4px 10px;
}

.multiple-column-filter {
  display: flex;
  flex-direction: row;
}

.filter-title {
  @include inter-semibold;
  font-size: 16px;
  color: $dark-grey;
  text-transform: initial;
  display: flex;
  align-items: center;
  @include pb-8;
}

.stiker-promo {
  position: absolute;
  top: 8px;
  right: 0;
  z-index: 1;
  text-align: center;

  &.best-price {
    background-color: $success;
    width: 90px;
  }

  &.top-collection {
    background-color: $gold;
    width: 110px;
  }
  &.discount {
    background-color: $red;
    align-items: center;
    border-radius: 50px;
    display: flex;
    height: 40px;
    width: 40px;
    justify-content: center;
    position: absolute;
    left: 4px;
    right: 0;
    top: 8px;
    
      .text-promo {
        font-size: 14px;
        line-height: 24px;
        @include inter-semibold;
        &.white {
          color: $white;
        }
      }
  }
  p {
    margin-bottom: 0;
    color: $white;
    font-size: 14px;
    @include inter-semibold;
  }
}

.sort-options {
  position: relative;
  border: solid 1px $light-grey;
  height: 24px;
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: $dark-grey;
    margin-bottom: 0;
  }

  img {
    width: 16px;
    padding-top: 2px;
  }

  ul {
    padding: 0;
    position: absolute;
    right: -20px;
    top: 24px;
    width: auto;
    display: none;
    z-index: 5;

    li {
      display: block;
    }
  }

  .sort-dropdown {
    box-shadow: (0px 2px 3px rgba(0, 0, 0, 0.2));
    background-color: white;

    >li>a,
    li>span {
      text-align: left;
      padding: 5px 15px 5px 10px;
      font-weight: 400;
      display: block;
      width: 100%;
      height: 100%;
      background-color: white;
      text-transform: inherit;
      margin-top: -1px;
      white-space: nowrap;

      &:hover {
        background-color: $light-grey;
      }
    }
  }
}

.new-arrivals-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    width: 24px;
    @include mr-8;
  }
}

.size-singleproduct {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  opacity: 0;
  transition: 1s;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include pb-8;
  @include pt-8;

  .single-size {
    padding: 0 4px;
  }

}

.singleproduct-divider {
  background: $light-grey;
  height: 1px;
}

.close-popup-product {
  width: 22px;
  position: absolute;
  top: 19px;
  right: 19px;
  cursor: pointer;

  @include breakpoint (small down) {
    top: 16px;
    right: 24px;
  }
}

.close-popup-currency, .close-popup-language {
  width: 16px;
  position: absolute;
  top: 14px;
  right: 19px;
  cursor: pointer;
}

.mobile-popup-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $menu-z - 10;
}

.reveal.large {
    @media print, screen and (min-width: 40em) {
        width: 90%;
        max-width: 94rem;
    }

    @include breakpoint (medium only) {
      width: 98%;
      margin-bottom: 64px;
    }

    @include breakpoint (small only) {
      width: 100%;
      margin: 0 !important;
      border-radius: 0;
    }
}

.no-result {
  @include inter-medium;
  span {
    @include inter-semibold;
  }
}

.showOtherSize {
  cursor: pointer;
}

.product-sheet {
  position: absolute;
  right: 8px;
  bottom: 8px;
}

.items-available {
  font-size: 14px;
  justify-content: center;
  .number {
    @include inter-semibold;
  }
}


// Swiper
.swiper-wrapper {
  z-index: $menu-z - 2;
}

.swiper-button-prev, .swiper-button-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  z-index: $menu-z - 1;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}


//search result
.search-result{
  font-size: 25px;
  color: $dark-grey;
  text-transform: uppercase;
  @include inter-semibold;
}

.number-results{
  color: $grey-2;
  font-size: 16px;
  text-transform: initial;
}

#results-search {
  color: $gold;
  @include inter-semibold;
  text-transform: initial;

}

.download-item {
  display: flex;
  align-items: center;
  div {
    margin-right: 16px;
  }
}

#productreveal {
  @include breakpoint (small down) {
    padding-top: 48px !important;
  }

  @include breakpoint (small down) {
    padding-top: 48px !important;
  }
}