// button
.btn {
  @include inter-medium;
  @include transition-basic;
  @include mt-16;
  @include mb-16;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  border: none;
  cursor: pointer;
  text-align: center;
  border-radius: 24px;
  padding: 8px 0;
  height: 40px;

  &.btn-primary {
    background-color: $gold;
    border: 1px solid $gold;
    color: $white;

    &:hover {
      background-color: $gold-hover;
    }
  }

  &.btn-padding {
    padding: 8px 32px;
  }

  &.btn-secondary {
    color: $gold;
    border: 1px solid $gold;
    border-radius: 24px;

    &:hover {
      color: $gold-hover;
    }

    * {
      font-size: inherit;
      color: inherit;
    }
  }

  &.btn-third {
    color: $gold;
    margin: 8px 0;
  }

  &.btn-fourth {
    color: $black;
    text-decoration: underline;
    padding: 0;
    margin: 0;
    height: 0;
  }

  &.btn-payments {
    color: $black;
    border: 1px solid $gold;
    background-color: $grey-paypal;
    border-radius: 0px;
    margin-top: 16px;
    margin-bottom: 0;
    padding: 0;
    height: min-content;

    &:hover {
      box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.05)
    }


    .fee {
      color: $red;
      font-weight: bold;
      text-transform: lowercase;
      margin-left: 8px;
      font-size: 12px;
    }

    .fee .euro {
      font-weight: bold;
    }
  }

  &.pay-subscription {
    margin: 0;
  }

  &.btn-no-margin {
    margin-top: 0;
    margin-bottom: 0;
  }

  &:active {
    position: relative;
    top: 1px;
  }

  &:disabled {
    opacity: .7;
  }

  &.btn-margin-0 {
    margin: 0;
  }

  &.btn-coupon {
    padding: 0 24px;
    margin-top: 4px;
  }
}

table .btn.btn-padding {
  @include breakpoint (small down) {
    padding: 8px 16px;
  }
}


// input

.input-text {

  label {
    @include transition-basic;
    top: 6px;
    left: 14px;
    color: $grey-2;
    font-size: 12px;
  }

  input {
    @include transition-basic;
    box-shadow: none;
    border: 1px solid $grey-3;
    border-radius: 3px;
    padding: 15px 10px;
    height: 3rem;

    &:focus {
      border-color: $gold;
      box-shadow: none;

      +label {
        color: $gold;
      }
    }
  }

  textarea {
    @include transition-basic;
    box-shadow: none;
    border: 1px solid $grey-3;
    border-radius: 3px;
    padding: 15px 10px;
    height: 7rem;

    &:focus {
      border-color: $gold;
      box-shadow: none;

      +label {
        color: $gold;
      }
    }
  }

}

select {
  border: 1px solid $grey-3;
  height: 3rem;
  border-radius: 3px;

  &:focus {
    border: 1px solid $gold;
  }
}


// checkbox
.styled-checkbox {
  position: absolute;
  opacity: 0;

  &+label,
  &+label a {
    position: relative;
    padding: 0;
    margin: 5px 0;

    &.is-invalid-label,
    &.is-invalid-label a {
      color: $red;
    }
  }

  &.radio {
    &+label:before {
      top: -1px !important;
      border-radius: 50% !important;
    }

    &:checked+label:after {
      top: 11px !important;
    }
  }

  &+label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    background: none;
    border: 1px solid $black;
    position: relative;
    top: -2px;
  }

  &:hover+label:before {
    background: $black;
  }

  &:focus+label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  &:checked+label:before {
    background: $gold;
  }

  &:disabled+label {
    color: $light-grey;
    cursor: auto;
  }

  &:disabled+label:before {
    box-shadow: none;
    background: $light-grey;
  }

  &:checked+label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 8px;
    background: $white;
    width: 2px;
    height: 2px;
    box-shadow:
      2px 0 0 $white,
      4px 0 0 $white,
      4px -2px 0 $white,
      4px -4px 0 $white,
      4px -6px 0 $white,
      4px -8px 0 $white;
    transform: rotate(45deg);
  }

  &.error + label {
    color: $red;
  }
}


input[type=checkbox]+label {
  margin: 0;
  position: relative;
  cursor: pointer;
  float: left;
  color: $black;
  line-height: 1.5;
  margin-bottom: 20px;
  //font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark-grey);

  @include breakpoint(small down) {
    font-size: 12px;
  }

  a {
    color: $black;
  }
}

input[readonly] {
  &.datepicker.picker__input {
    background-color: $white;
  }
}

//background input
input,
textarea {

  // Disabled/readonly state
  &:disabled,
  &[readonly] {
    background: $light-grey;
  }
}

//tasto esci profilo
[type='submit'],
[type='button'] {
  appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0px;
  cursor: pointer;
  background: none;
  padding: 10px 0px;
}


textarea.notes {
  height: 100px;
}

//select
.select {
  height: auto;
  border: 1px solid $dark-grey;
  @include inter-regular;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 3px;

  &:focus {
    border: 1px solid $gold;
    box-shadow: none;
  }

}

//radio

.radio {
  margin: 0.1rem 0;
  margin-bottom: 10px;

  input[type="radio"] {
    position: absolute;
    opacity: 0;

    +.radio-label {
      margin: 0;

      &:before {
        content: '';
        background: none;
        border-radius: 100%;
        border: 1px solid $gold;
        display: inline-block;
        width: 1.3em;
        height: 1.3em;
        position: relative;
        top: 1px;
        margin-right: 8px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
        vertical-align: text-top;
      }
    }

    &:checked {
      +.radio-label {
        &:before {
          background-color: $gold;
          box-shadow: inset 0 0 0 4px $light-gray;
        }
      }
    }

    &:focus {
      +.radio-label {
        &:before {
          outline: none;
          border-color: $gold;
        }
      }
    }

    &:disabled {
      +.radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $light-gray;
          border-color: darken($light-gray, 25%);
          background: darken(white, 25%);
        }
      }
    }

    +.radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

//accordion
.accordion-title {
  border: none;
  color: $black;
  font-size: 20px;
  padding: 1rem 0rem;
  @include inter-regular;

  &:hover,
  &:active,
  &:focus {
    background-color: $white;
    color: $gold;
  }

  :last-child .is-active {
    border-bottom: 1px solid $dark-grey;
  }
}

.accordion-content {
  border: none;
  padding: 1rem 0rem;
}