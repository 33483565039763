#cart-container {
    margin-bottom: 60px;
    padding-top: 30px;
    @include breakpoint(medium) {
        padding-top: 40px;
    }

    @include breakpoint(small) {
        padding-top: 0px;
    }
}

.cart-message {
    text-align: center;
    background-color: #C5ED97;
    border: 1px solid #4CAF50;
    border-radius: 24px;
    padding: 8px 24px;
    margin-bottom: 8px;
}

.cart-product__title {
    font-size: 20px;
    @include breakpoint(medium down) {
        margin-top: 20px;
    }
}

.cart-product__subtitle {
    font-size: 16px;
    display: block;
    margin: 2px 0;
}

.btn-cart-remove {
    border: 1px solid $black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin-left: 7px;

    img {
        width: 19px;
    }
}

.cart-product__buttons {
    text-align: center;
    padding: 35px 0;

    button {
        @include inter-bold;
        background-color: $black;
        border: 1px solid $black;
        color: $white;
        width: 182px;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.1em;
        padding: 9px 0 7px;
        position: relative;
        margin: 0 5px;

        &.-light {
            color: $black;
            background-color: white;
        }

        &:active {
            top: 1px;
        }
    }
}

.cart-product__prices {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding-bottom: 30px;

    span {
        @include inter-bold;
        display: block;
    }

    small {
        font-weight: bold;
        text-decoration: line-through;
        color: $dark-grey;
    }
}

.cart-product__price-label {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.05em;
}

.cart-product__price-big {
    font-size: 22px;
    margin-top: -5px;
}

.cart-resume {
    margin-left: 50px;
    @include breakpoint(medium down) {
        margin-left: 0;
    }
}

.cart-resume__top {
    //margin-bottom: 20px;

    p {
        @include inter-medium;
        font-size: 16px;
        line-height: 1.8;
        text-align: left;
        margin-bottom: 0px;

        &.right {
            text-align: right;
        }

        &.-light {
            opacity: 0.6;
        }
    }

    &.-right {
        text-align: left;

        p {
            font-size: 16px;
            line-height: 1.8;
        }
    }
}

.coupon-input {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    > * {
        width: 50%;
    }

    input {
        margin: 0 10px 0 0;
        height: 40px;
        box-shadow: none;
        border-color: $grey-2;
        text-align: center;
        font-weight: bold;
        letter-spacing: 0.04em;
        font-size: 14px;
        padding: 8px 8px 6px;
        color: $black;
    }
}

.coupon-label {
    font-size: 16px;
    margin-bottom: 4px;
    transition: all 0.15s ease-in-out;
    top: 6px;
    left: 14px;
    text-transform: uppercase;
    color: $black;
    font-size: 14px;
}

.country-select {
    @include inter-bold;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 12px;
    height: 34px;
    padding-left: 11px;
    border-color: $grey-2;
}

.cart-total {
    padding: 20px 0;
    text-align: left;

    span {
        @include inter-medium;
        display: block;
    }
}

.cart-total__label {
    font-size: 22px;
    margin-top: -5px;
    text-align: left;
    text-transform: uppercase;
}

.cart-total__price {
    font-size: 22px;
    margin-top: -5px;
    text-align: right;
}

.select-carrier {
    margin: 0 0 15px;
}

.cart-dimension {
    font-size: 16px;
    margin: 2px 0;
}
.cart-update .qty-input-container {
    margin-top: 3px;
}

.description-product_cart {
    display: flex;
    align-items: center;
}

#checkout-container {
    margin-bottom: 60px;
    padding-top: 30px;
    @include breakpoint(medium) {
        padding-top: 40px;
    }

    @include breakpoint(small) {
        padding-top: 0px;
    }
}

.cart-product {
    border-bottom: 1px solid $grey-3;
    margin-bottom: 35px;
}

.btn-cart-remove,
.btn-update {
    border: 1px solid $grey-2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    @include ml-8;
    background-color: $white;

    img {
        width: 20px;
    }
}

.cart-resume {
    margin-left: 50px;
    @include breakpoint(medium down) {
        margin-left: 0;
    }
}

.cart-resume__top {
    //margin-bottom: 20px;

    p {
        @include inter-medium;
        font-size: 16px;
        line-height: 1.8;
        text-align: left;
        margin-bottom: 0px;

        &.right {
            text-align: right;
        }

        &.-light {
            opacity: 0.6;
        }
    }

    &.-right {
        text-align: left;

        p {
            font-size: 16px;
            line-height: 1.8;
        }
    }
}

.coupon-input {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    > * {
        width: 50%;
    }

    input {
        margin: 0 10px 0 0;
        height: 40px;
        box-shadow: none;
        border-color: $grey-2;
        text-align: center;
        font-weight: bold;
        letter-spacing: 0.04em;
        font-size: 14px;
        padding: 8px 8px 6px;
        color: $black;
    }
}

.coupon-label {
    font-size: 16px;
    margin-bottom: 4px;
    transition: all 0.15s ease-in-out;
    top: 6px;
    left: 14px;
    text-transform: uppercase;
    color: $black;
    font-size: 14px;
}

.country-select {
    @include inter-bold;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 12px;
    height: 34px;
    padding-left: 11px;
    border-color: $grey-2;
}

.cart-total {
    padding: 20px 0;
    text-align: left;

    span {
        @include inter-medium;
        display: block;
    }
}

.cart-total__label {
    font-size: 22px;
    margin-top: -5px;
    text-align: left;
    text-transform: uppercase;
}

.cart-total__price {
    font-size: 22px;
    margin-top: -5px;
    text-align: right;
}

.select-carrier {
    margin: 0 0 15px;
}

.cart-dimension {
    font-size: 16px;
    margin: 2px 0;
}
.cart-update .qty-input-container {
    margin-top: 3px;
}

.description-product_cart {
    display: flex;
    align-items: center;
}

.shareWishlistButton {
    text-align: left;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-content: center;
    > * {
        color: $gold;
        margin-bottom: 0px;
    }
}

hr.share-wishlist {
    border-top: 1px solid $grey-2;
}

.reveal {
    top: 0px !important;
    margin-top: 16px !important;
    z-index: $menu-z - 1 !important;
    border-radius: 8px;
    &.wishlist {
        @include breakpoint(medium down) {
            margin: 0 auto;
            width: 90%;
            height: auto;
            min-height: auto;
            top: 20% !important;
        }
    }

    @include breakpoint(medium down) {
        @include pb-64;
    }
}

.share-button-div {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.share-button {
    width: 44px;
    height: 44px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.whatsapp {
        background: $green-whatsapp;
    }
    &.mail {
        background: $gold;
    }
    &.facebook {
        background: $blue-facebook;
    }
    > a > img {
        width: 24px;
        margin-bottom: 2px;
    }
}

.popupShareLink {
    border: 1px solid $grey-2;
    padding: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-right: none;
}

.popupShareLinkButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $gold;
    color: $white;
}

.cart-summary-detail,
.subscription-price-column {
    hr {
        border: none;
        border-top: 1px solid $grey-3;
        margin: 8px 0;
    }

    &.total {
        @include inter-bold;

        * {
            font-weight: inherit;
        }
    }
}

.cart-title {
    @include inter-medium;
    @include mb-24;
    margin-top: 0;
    font-size: 34px;
    line-height: 32px;

    &.summary {
        font-size: 25px;
        line-height: 32px;
    }

    @include breakpoint(small down) {
        font-size: 30px;
        @include mt-16;
    }
}

.total-price {
    @include inter-medium;
    @include mb-8;
    float: right;
}

.empty-cart {
    text-align: right;

    @include breakpoint(medium down) {
        text-align: left;
    }
}

.show-other-size {
    text-transform: uppercase;
    color: $gold;
    @include mb-16;
    font-size: 14px;
}

.countdown-title {
    @include inter-medium;
}

.number-countdown {
    @include ml-16;
}

#expireDateMinutes,
#expireDateSeconds,
#expireDateDays,
#expireDateDaysMobile,
#expireDateMinutesMobile,
#expireDateSecondsMobile {
    font-size: 22px;
    color: $gold;
    @include inter-semibold;
}

.minutes-seconds-cart {
    text-transform: uppercase;
    @include inter-medium;
    font-size: 12px;
    margin-left: 4px;
}

.cart-count-container {
    background-color: $light-grey;
    border: 1px solid $gold;
    padding: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.countdown-cart-mobile {
    @include breakpoint(small down) {
        @include mt-24;
    }
}

#cart-count-icon {
    img {
        margin-right: 0;
    }
}
