#designers-container {
  @include pt-8;

  h2 {
    font-size: 60px;
    line-height: 64px;
    @include mb-8;
    @include breakpoint(small down) {
      font-size: 50px;
      line-height: 56px;
    }
  }

  a.card:hover {
    background-color: $black;
    border-radius: 50%;
    color: $white;
  }
}

.designer-letters {
  justify-content: space-around;
  padding: 16px 0;
}

.letters-div {
  @include pt-8;
  @include pb-8;
}

.sticky-letters {
  position: sticky;
  top: 123px;
  background: $white;
  @include mb-24;
  border-bottom: 1px solid $dark-grey;
  @include breakpoint(medium down) {
    top: 91px;
  }
  @include breakpoint(small down) {
    top: 55px;
  }
}

.scrolling-wrapper {
  @media screen and (max-width: 63.9em) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
  }
}

.card {
  min-width: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}

.bg-letters-active {
  background-color: $black;
  border-radius: 50%;
  color: $white;

  &.catalog-filter {
    border-radius: 12.5px;
  }
}

.letter-box {
  @media screen and (max-width: 63.9em) {
    width: 100%;
    padding: 24px 24px 0 24px;
  }
}

.designer-list {
  li {
    width: 25%;
    float: left;
    @include mt-8;

    @include breakpoint(medium down) {
      width: 50%;
    }

    @include breakpoint(small down) {
      width: 100%;
    }
  }
}

.designer-search {
  max-width: 25vw;
  margin-bottom: 0px;
  border: 0px !important;
  border-bottom: 1px solid !important;
  border-radius: 0px;
  text-align: center;
  @include breakpoint(medium only) {
    max-width: 40vw;
  }

  @include breakpoint(small only) {
    max-width: 80vw;
  }
}

.search-designer-div {
  display: flex;
  justify-content: center;
  @include pt-8;
}

.designers-text-bottom {
  p {
    font-size: 14px;
    line-height: 24px;
  }
}
