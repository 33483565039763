
.main-menu {
  padding: 24px 0;

  @media screen and (max-width: 718px) {
    display: none;
  }

  @media screen and (max-height: 639px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    padding: 8px 0;
  }

  @media screen and (min-height: 640px) and (max-height: 784px) {
    padding: 8px 0;
  }

  ul {
    list-style: none;
  }

  > ul > li {
    display: inline-block;
    margin-right: -7px;
    
      &:hover::after {
        content: '';
        opacity: 1;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        height: 3px;
      }
  }

  .main-menu-item{
    color: $white;
    text-transform: uppercase;
    cursor: pointer;
    padding: 0 16px;
    @include breakpoint (medium down) {
      padding: 0 6px;
    }
    a {
      color: $white;
    }
    &.selected {
      color: $white;
    }
  }
}

#submenus-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: $menu-z;

  .submenu-overlay {
    background-color: rgba($white, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .submenu-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .left, .right {
      height: 100%;
      background-color: $black-alpha;
    }

    .right {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }

    @media screen and (max-width: 1564px) {
      max-width: calc(11/12 * 100%);
    }
    @media screen and (min-width: 1565px) {
      max-width: calc(8/12 * 100%);
    }
  }
}

.submenu {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  &.mobile {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 48px;
  }

  ul {
    position: relative;
    min-height: 324px;
  }

  .items {
    width: 100%;
  }
}

// For display in CMS editor
.submenu-image {
  height: 300px;
  width: 500px;
  background-size: auto;
  background-repeat: no-repeat;
}

#submenus-container .submenu-image-container {
  display: block;
  width: 400px;
  height: 200px;
  position: absolute;
  bottom: 105%;

  .submenu-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: top left;
  }

  @include breakpoint (medium only) {
    width: 240px;
    height: 150px;
  }
}

.submenu-item {
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $grey-2;
  height: 50px;
  width: 280px;
  display: flex;
  align-items: baseline;
  cursor: pointer;

  @include breakpoint (medium down) {
    width: 230px;
  }

  &.see-all {
    color: $gold;
    margin-top: 10px;
    font-size: 16px;
    text-transform: uppercase;
    @include mt-24;
    * { color: inherit; }

    @include breakpoint (small down) {
      margin-top: 0;
    }
  }

  &.mobile {
    color: $white;
    font-size: 25px;
    line-height: 32px;
    margin: 16px 0;
  }

  .arrow {
    width: 7px;
    margin-left: 24px;
    transition: all .4s;
  }
}

.main-menu.open {
  .main-menu-item:not(.selected) {
    color: $grey-2 !important;
    * { color: inherit; }
  }
}

.submenu-item:not(.mobile):not(.see-all) {
  &:hover, &.selected {
    color: $white;
    * { color: inherit; }

    .arrow {
      margin-left: 40px;
    }
  }
}

.subsubmenu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 48px;
  overflow: auto;

  .columns-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    max-height: 100%;

    &.kids {
      align-items: center;
      height: 100%;
    }
  }

  .flex-column {
    width: 100%;
  }

  &.mobile {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 8px;

    .subsubmenu-title {
      text-transform: uppercase;
    }
  }
}

.subsubmenu:not(.mobile) {
  @include breakpoint (medium down) {
    padding: 0 20px;
  }
}

#submenus-container .submenu, #submenus-container .subsubmenu {
  height: calc(100vh - 131px);
  margin-top: 131px;
  align-items: center;

  @media screen and (min-width: 719px) and (max-width: 1024px) {
    padding-left: 20px;
    height: calc(100vh - 99px);
    margin-top: 99px;
  }

  @media screen and (max-height: 784px) {
    padding-left: 20px;
    height: calc(100vh - 99px);
    margin-top: 99px;
  }
}

#submenus-container .submenu {
  @media screen and (min-height: 641px) and (max-height: 784px) {
    .submenu-item {
      font-size: 22px;
      line-height: 44px;
    }

    ul {
      margin-top: 150px;
    }
  }
}

#submenus-container .subsubmenu.kids {
  padding: 0;
  margin-top: 0;
  align-items: flex-start;
  justify-content: center;
}

#submenus-container .subsubmenu.kids-top {
  padding: 0;

  .subsubmenu {
    padding-left: 40px;
  }

  @include breakpoint (medium down) {
    .subsubmenu {
      padding-left: 20px;
    }

    .submenu-item {
      width: 200px;
    }

    .submenu-item:not(.see-all) {
      font-size: 20px;
    }

    .submenu-item.see-all {
      margin-top: 0;
    }
  }
}

#submenus-container .subsubmenu {
  @include breakpoint (medium down) {
    overflow-y: scroll;
  }
}

.subsubmenu-title {
  color: $white;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  height: 35px;
  display: flex;
  margin-bottom: 16px;
}

.subsubmenu-item {
  color: $white;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  cursor: pointer;
  margin: 8px 0;

  &.see-all {
    color: $gold;
    text-transform: uppercase;
    margin-top: 10px;
  }

  @include breakpoint (small down) {
    margin: 8px 0;
  }

  &.language, &.currency {
    color: $white;
    padding: 8px;

    * {
      color: inherit;
      text-decoration: inherit;
    }

    &.selected, &:active {
      color: $black;
      background-color: $white;
      border-radius: 10px;
      @include breakpoint (medium down) {
        border-radius: 0px;
      }

    }

    .language, .currency {
      margin-left: 8px;
    }

    .currency {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &:active, &:hover {
    color: $gold;
  }
}

.secondary-menu {
  display: none;
  position: absolute;
  background-color: $white;
  left: 0;
  right: 0;
  max-width: 1170px;
  margin: 5px auto;
  /* padding: 42px; */
  z-index: 10;

  ul li{
    text-align: left; 
  }

    .column-block {
      margin:0;

      .content-img {
        width: 192.8px;
        height: 192.9px;
        overflow: hidden;

        div.img {
          height: inherit;
          background-size:cover;
          background-repeat: no-repeat;
          background-position: 50%;
          transition: all .3s ease-out;
        }
      }

      a:hover {
        div.img { transform: scale(1.1); }
      }

      h4 {
        @include inter-regular;
        text-align: left;
        margin-top: 16px;
      }
    }
}


.menu-side-img {
  &.cuscini{
    width: 100%;
    height: 77%;
  }
 

    a {
      display: block;
    }

    span {
      display: block;
      font-size: 16px;
      margin: 12px 0 5px;
      text-align: left;
    }
}

.menu-big-img {
  text-align: center;

    a {
      display: block;
      margin-bottom: 30px;
    }

    span {
      display: block;
      font-size: 18px;
      letter-spacing: 0.01em;
      margin: -6px 0 12px;
    }
}

.secondary-menu__title,
.secondary-menu__title a {
  @include inter-medium;
  font-size: 18px;
  margin-bottom: 15px;
  text-align: left;
}

//menu mobile

#mobile-menu-breadcrumb {
  color: $white;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 150px;
  padding-left: 48px;
  font-size: 3.7vw;

  @include breakpoint (medium only) {
    top: 200px;
    font-size: 1.7vw;
   }

  .separator {
    margin: 0 8px;
  }

  * {
    color: inherit;
    font-size: inherit;
  }
}

#mobile-menu {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 48px;
}

.mobile-menu-item {
  color: $white;
  font-size: 25px;
  @include inter-semibold;
  display: flex;
  align-items: baseline;
  cursor: pointer;
  margin: 8px 0;

  * {
    font: inherit;
    color: inherit;
  }

  input {
    padding: 0;
  }

  .icon {
    width: 24px;
    height: 24px;
    position: relative;
    top: 3px;
  }

  &.secondary {
    font-size: 21px;
    line-height: 21px;
    margin-left: 40px;
    color: $grey-3;
  }
}

.close-mobile-submenu {
  width: max-content;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: $white;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 12px;
    margin: 16px
  }
}

#mobile-menu, #submenus-container-mobile, #subsubmenus-container-mobile, #kids-subsubmenus-container-mobile {
  position: absolute;
  margin-top: 150px;
  padding-top: 50px;
  width: 100%;

  @include breakpoint (medium only) {
    margin-top: 200px;
  }

  @media screen and (max-height: 500px) {
    height: 100%;
    margin-top: 0;
    padding-top: 0;
  }
}

#submenus-container-mobile, #subsubmenus-container-mobile, #kids-subsubmenus-container-mobile {
  overflow-y: scroll;
}

.header-main__flex {
  display: flex;
  justify-content: center;
}

.header-main__side {
  align-self: center;
  width: 100px;
  display: flex;
  justify-content: flex-start;

    &.-right {
      width: 100%;
      justify-content: flex-end;
      @include breakpoint(medium down) {
        width: 0;
      }
    }
}

.header-utilities__cart.inline-block .full-cart{
  width: 22px;
  top: -3px;

  &.wishlist {
    width: 22px;
    top: -2px;
  }
}

.av-search {
  cursor: pointer;
}

.header-utilities__cart.inline-block .full-cart{
  width: 22px;
  top: -3px;
}

.av-search {
  cursor: pointer;
}

.header-utilities__space {
  img {
    width: 24px;
    @include mr-24;
    cursor: pointer;
  }
}

.header-main__logo {
  width: 197px;

  @include breakpoint(small down) {
    width: 200px;
    padding: 0px;
  }
}

.header-utilities__language-dropdown {
  position: relative;
  cursor: pointer;

    span {
      position: relative;
      color: $white;

      &::after {
        content: "";
        background-image: url(/skins/current-skin/images/icons/arrow-down.svg);
        background-repeat: no-repeat;
        width: 26px;
        height: 6px;
        position: absolute;
        right: 0;
        top: 10px;
        left: 61px;
      }

      &:hover {
        color: $gold;
      }
    }

    li, a {
      color: $white;
    }

    ul {
      display: none;
      position: absolute;
      list-style: none;
      background-color: $black;
      width: 120px;
      text-align: center;
      top: 25px;
      left: -25px;
      padding: 4px;
      &.language {
        column-count: 4;
        width: auto;
        padding: 16px;
      }
    }

    &:hover {

      ul {
        display: block;
      }
    }
}

.header-utilities__language-dropdown.login a.login-header{
  &::after {
    content: "";
    background-image: url(/skins/current-skin/images/icons/arrow-down.svg);
    background-repeat: no-repeat;
    width: 26px;
    height: 6px;
    position: absolute;
    right: 0;
    top: 10px;
    left: 109%;
}
}


.off-canvas.is-transition-overlap.is-open {
  box-shadow: 0 0 10px hsla(0,0%,4%,.2);
}

.menu-icon-left {
  width: 21px;
  margin-right: 5px;
}

.off-canvas{
  background-color: $light-grey;
}

.logout{
  text-transform: uppercase;
  font-size: 14px;
  padding: 0px;
}

.sub-menu {
  position: absolute;
  margin-left: 48px;
  background: $black;
  top: 0px;
  width: 300px;
  height: calc(100vh - 65px);
  z-index: 2;
  padding-bottom: 6px;
}

.detail-menu {
  position: absolute;
  margin-left: 348px;
  height: calc(100vh - 65px);
  width: calc(100vw - 348px);
  background: grey;
  top: 0px;
  background-size: cover;
  z-index: $menu-z;

  > div {
    padding-top: 20vh;
  }

  hr {
    border-color: $white;
  }
  
  &.on-top {
    z-index: $menu-z + 1;
  }
}

.right-menu-desktop-content {
  width: calc(100vw - 48px);
  @include breakpoint(small down) {
    width: calc(100vw);
  }
}

.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;

  &.menu {
    margin-bottom: 26px;
  }

}

.icons-list {
  display: flex;
  flex-direction: column;
  margin-top: 20vh;

  &.icons {
    margin-top: calc(20vh + 5px)
  }

}

.block {
  display: block !important;
}

.sub-menu-item, .sub-menu-item-mobile {
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $grey-2;
  height: 50px;
  margin-left: 30px;
  display: flex;
  cursor: pointer;

  &.current {
    color: $white;
    * { color: inherit; }
  }

  * {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.sub-menu-item.with-arrow::after {
  content: url('/skins/current-skin/images/icons/arrow-grey.svg');
  margin-left: 8px;
}

.close-sidemenu {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.close-big-menu {
  width: 50px;
  padding: 12px;
  position: fixed;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 82px;
  top: 30px;
  right: calc(50vw - 178px);
  cursor: pointer;
  background-color: rgba($grey-2, 0.5);
  border-radius: 50%;
}

.padding-left-30 {
  padding-left: 30px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-60 {
  margin-top: 60px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.big-menu-item {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: normal;
  color: $white;

  &.see-all {
    color: $gold;
  }

  &.see-all::after {
    content: url('/skins/current-skin/images/icons/arrow-gold.svg');
    margin-left: 8px;
  }
}

.blocked-scrolling {
  margin: 0; height: 100%; overflow: hidden
}

.blocked-event {
  pointer-events: none;
}

.mobile-menu {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 54px;
  background: $black;
  z-index: $header-z;
  padding-left: 1vw;
  padding-right: 10vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 719px) and (min-height: 640px) {
    display: none;
  }
}

.first-mobile-icon {
  margin-right: 24px;
  @include breakpoint (medium only) {
    @include mr-48;
   }
}

.mobile-menu-view {
  position: fixed;
  top: 60px;
  right: 0;
  left: 0;
  height: calc(100vh - 110px);
  background: $black;
  z-index: $header-z;
  overflow-y: scroll;
}

.mobile-first-image {
  display: block;
  height: 150px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  @include breakpoint (medium only) {
    height: 200px;
   }
  
  .submenu-image {
    width: 100%;
    height: 100%;
    background-size: cover;

    @media screen and (max-height: 500px) {
      display: none;
    }
  }
}

.mobile-menu-list {
  margin: 32px 0 0 0;
  // height: calc(90% - 101px);
  // overflow-y: scroll;
}

.mobile-menu-icons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 3px;
}