.cart-summary {
    background-color: $light-grey;
    padding: 16px;
}

.cart-summary__line {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 4px 0;

    span {
      @include inter-bold;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 0.05em;
    }

    .label {
      font-size: 11px;
    }
}

.cart-summary__separator {
  border: none;
  border-top: 1px solid $grey-2;
  margin: 8px 0 10px;
}

.margin-top-info-cart{
  @media (min-width: 481px) and (max-width: 767px) {
   margin-top: 65px;
  }
}

.small-note {
  font-size: 14px;
  color: $grey-2;
  span {
    font-size: 14px;
    color: $grey-2;
    @include inter-medium;
  }
}