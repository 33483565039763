// main header
.header-main {
  z-index: 100;
  background-color:$black;
  display: flex;
  align-items: center;
}

.header-main.sticky {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: $header-z;
  padding: 8px 0;
}

//right part

.header-main__side {

  @include breakpoint (medium down) {
    visibility: hidden; // Hide but keep popups visible
  }

  &:hover .profile-menu-drop {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
      
  }

  .profile-menu-drop {
      position: absolute;
      /* transition: all .5s; */
      left: -20px;
      right: auto;
      top: 25px;
      margin: 0 auto;
      background-color: $white;
      width: 60px;
      pointer-events: none;
      text-align: center;
      padding: 20px 5px 5px 5px;
      z-index: 10;
      opacity: 0;
      transform: translateY(-110px);

        &.drop-language {
          left: -24px;
          width: 68px;
        }

        &.drop-price {
          left: -19px;
          width: 120px;
          background-color: $light-grey;
          border: 1px solid $dark-grey;
          width: 120px;
          text-align: center;
          padding: 14px 0px 0px 0px;
          z-index: 20;
        }

  }

  input {
      background-color: $white;
      width: 92%;
      font-size: 16px;
      @include inter-medium;
      border: none;
      cursor: pointer;
      transition: all .15s;
      background-color: $black;
      color: $white;
      width: 114px;
      text-align: center;
      padding: 5px 0;

      &:hover {
          color: $gold;
      }
  }

  a:hover {
      color: $gold;

  }
}

.top-banner{
  background-color: $red;
  padding: 6px 32px;

    p {
    margin-bottom: 0px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: $white;
    }
}

.count-cart {
    color: $white;
    position: absolute;
    font-size: 11px;
    @include inter-medium;
    background-color: $gold;
    display: block;
    top: 0px;
    left: 17px;
    height: 15px;
    width: 15px;
    text-align: center;
    border-radius: 20px;
    padding: 0px 2px;

    @include breakpoint (small down) {
      height: 11px;
      width: 11px;
    }
}


.js-change-currency {
  width: fit-content;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  &.selected {
    background: $grey-3;
  }
}

.codeCurrency {
  float: right;
  color: $grey-2;
}
.header-currency {
  color: $white;
  cursor: pointer;
  @include mr-8;
    &::after {
      content: "";
      background-image: url(/skins/current-skin/images/icons/arrow-down.svg);
      background-repeat: no-repeat;
      width: 26px;
      height: 6px;
      position: absolute;
      right: 0;
      top: 12px;
      left: 38px;
    }
}

.language {
  font-size: 14px;
  text-transform: uppercase;
}

.languages-popup {
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: $grey-3;
  }

  &.selected {
    background-color: $grey-3;
  }
}

#cart-popup-modal {
  background-color: $white;
  position: absolute;
  top: 35px;
  right: 30px;
  width: 400px;
  box-shadow: 0 2px 20px hsla(0,0%,41%,.14);

  @include breakpoint (small down) {
    visibility: visible;
    top: -17px;
    right: 0;
    max-width: 100vw;

    .product-image {
      display: none;
    }
  }

  .product-image img {
    width: 100%;
  }

  .close-icon {
    width: 12px;
    margin: 8px 16px 0 0;
    cursor: pointer;
  }

  img:not(.close-icon) {
    cursor: default;
  }

  .product-row {
    border-bottom: 1px solid $grey-2;
    margin-bottom: 8px;
    padding-bottom: 8px;
  }

  .model-info {
    border-bottom: 1px solid $grey-2;
    margin-bottom: 8px;
  }

  .model-info:last-of-type {
    border: none !important;
  }

  .product-info {
    p, span {
      font-size: 14px;
      line-height: 16px;
    }

    p {
      margin-left: 10px;
    }
  }

  .product-name {
    width: max-content;
    @include mb-8;
    @include inter-bold;
  }

  .cart-total-row .btn {
    padding: 8px 64px;

    &:hover {
      color: $white;
    }
  }
}

#cart-popup-modal::after {
  content: "";
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  background-color: $light-grey;
  position: absolute;
  top: -3px;
  right: 17px;

  @include breakpoint (small down) {
    display: none;
  }
}

#cart-popup-modal.product-popup-cart {
  z-index: $header-z;
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
}

#js-hook-title {
  border-bottom: 1px solid $gold;
  margin-bottom: 8px;
}