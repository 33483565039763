.login-subscribe-container {
  margin: 0 100px;
  margin-bottom: 40px;
  margin-top: -330px;
  background: white;
  box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  @include breakpoint(medium down) {
    margin: 0 50px;
    margin-top: -330px;
  }
  @include breakpoint(small down) {
    margin: auto !important;
    margin-top: 20px !important;
  }
}

.login-column {
  z-index: 1;
}

.login-title {
  font-size: 30px;
  margin: 0 0 30px;
  padding: 0 0 5px;
  text-align: center;
  @include breakpoint(small down) {
    font-size: 30px;
  }

    &.-small {
      font-size: 30px;
      color: $black;
    }
}

.login-link {
  @include inter-bold;
  display: inline-block;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.1em;
}

.login-header {
  height: 220px;
  background-size: cover;
  @include breakpoint(small down) {
    height: 180px;
  }
  &.login-subscribe {
    height: 320px;
  }
}

.bg-black {
  background: $black;
}

.subtitle-white {
  color: $white;
  @include inter-semibold;
  font-size: 25px;
  @include pt-24;
  @include pb-24;
  text-transform: uppercase;
  margin-bottom: 0;
}

.subtitle-login {
  @include inter-medium;
  font-size: 25px;
  color: $dark-grey;
  @include mt-32
}

.title-login {
  @include inter-semibold;
  font-size: 34px;
  color: $dark-grey;
}

.text-login {
  @include inter-regular;
  font-size: 16px;
  color: $dark-grey;
  @include mt-24;
  @include mb-24;
}

.upper-box {
  box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.12);
  @include mb-16;
  width: 100%;
  background-color: $white;
  
  .subtitle-login {
    @include mt-16;
    @include mb-16;
  }
  .title-login {
    @include mt-24;
  }
}

.line-divider {
  height: 1px;
  background: $grey-2;
  width: 40%;
}

#login-form, #password-recover-form, #login-form-otp, #password-new-form, #password-new-form-email, #password-new-form-token {
  margin: 0 56px;
  @include breakpoint(medium down) {
    margin: 0 20px;
  }
  @include breakpoint(small down) {
    margin: 0 20px;
  }
  .title-login {
    margin-top: 17px;
    @include mb-24;
  }
}

.password-forgot {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: $dark-grey;
  cursor: pointer;
}

.login-arrow {
  width: 16px;
  height: 8px;
  margin-top: 19px;
  margin-left: 8px;
  transition: 1s;
}

.open-subscribe {
  cursor: pointer;
  padding: 0 20px;
  justify-content: center
}

.login-div {
  position: relative;
  width: 100%;
}

.subscribe-div {
  position: relative;
  display: none;
  min-height: 100%;
  z-index: 10;
  background: $white;
  width: 100%;
  &.dropshipping {
    min-height: 0;
  }
}

#subscribe-form {
  margin: 0 56px;
  @include breakpoint(medium down) {
    margin: 0 20px;
  }
  @include breakpoint(small down) {
    margin: 0 20px;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.header-text-div {
  padding-top: 15px;

  @include breakpoint(small down) {
    padding-top: 50px;
  }

  &.login-subscribe {
    padding-top: 120px;
  }
}

.header-text {
  @include  inter-semibold;
  font-size: 50px;
  line-height: 56px;
  color: $black;
  @include breakpoint(small down) {
    font-size: 35px;
    line-height: 48px;
  }

  &.long {
    @include breakpoint(small down) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  &.gold {
    color: $gold;
  }
}

.recover-hint {
  margin-bottom: 16px;
  font-size: 12px;
}