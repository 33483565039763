// colors
$black: #0F0F0F;
$black-alpha: rgba(15, 15, 15, 0.95);
$black-hover: #141414;
$white: #ffffff;
$gold: #c1973c;
$gold-alpha: #c1973c56;
$gold-hover: #bb8f31;
$dark-grey: #424242;
$grey-2: #828282;
$grey-3: #e4e4e4;
$light-grey: #F0F0F0;
$table-bg-blue: #0078c1;
$table-bg-green: #88bd49;

$success: #1cc200;
$grey-paypal: #eee;
$blue-facebook: #1877f2;
$blue-facebook-hover: #1269db;
$green-whatsapp:#3FC351;
$red: #D81616;

$box-shadow: rgba(34, 34, 34, 0.12);


// Slick slider
$slick-font-path: '/skins/griffati/fonts/slick/';
$slick-loader-path: '/skins/griffati/images/slick/';

// z-indexes
$header-z: 1000;
$menu-z: 900;