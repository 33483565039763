.am-main-header-wrapper {
  position: absolute;
  width: 100%;
  z-index: 100;
  transition: background 0.5s;
}


.am-main-header {
  display: block;
  overflow: hidden;

  @include breakpoint (small down) {
    padding: 4px 15px;
  }

  @include breakpoint (medium down) {
    max-width: inherit;
  }
}

.am-main-header-wrapper-search {
  display: flex;
  justify-content: center;
}

.av-header-logo {
  width: 100%;
  display: block;
  @include mt-8;
  box-sizing: border-box;
  @include breakpoint (small down) {
   width: 197px;
   margin-top: 3px;
  }
}

.av-menu-wrapper {
  box-sizing: border-box;
  margin-top: 164px;
  margin-right: -15px;
  overflow: hidden;
  justify-content: center;

  @include breakpoint (medium only) {
    margin-top: 120px;
  }

  @include breakpoint (small down) {
    @include mt-32;
  }
}


.av-menu-wrapper a {
  display: block;
  text-align: center;
}

.av-text-bianco{
	color: $white;
	&:visited{
		color:$white!important;
	}
}


  .icon-icon_28:before {
    content: "";
    background-image: url(/skins/current-skin/images/icons/close-mobile-menu.svg);
    background-size: cover;
    background-position: 50%;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "icons";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 50px;
    height:50px;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    /* font-size: 120%; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}


.av-menu-scroll {
    position: absolute;
    top: 350px;
    bottom: 30px;
    left: 30px;
    right: 30px;
    overflow: hidden;
    overflow-y: scroll;

    @include breakpoint (medium only) {
      top: 264px;
    }

    @include breakpoint (small down) {
      top: 164px;
    }

    @media (min-width: 481px) and (max-width: 767px) {
      top: 170px;
     }
}

.av-main-wrapper-center {
  max-width: 1250px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  padding: 0px 15px;
}

.av-search-wrapper .search-form {
  overflow: hidden;
  margin-bottom: 10px;
  padding: 0 15px;
  border-bottom: 1px solid $gold;

  @include breakpoint (small down){
    overflow: hidden;
    margin-bottom: 10px;
    padding: 0 15px;
    border-bottom: 1px solid $gold;
}
}


.av-search-wrapper .search-form input[type=text] {
  font-size: 76px;
  background: none;
  border: none;
  outline: none;
  color: $white;
  float: left;
  width: 80%;
  margin-bottom: 30px;
  &::placeholder {
    color: $white;
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $white;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $white;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $white;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $white;
  }

  @include breakpoint (small down){
  font-size: 30px;
  background: none;
  border: none;
  outline: none;
  margin-bottom: 5px;
  font-weight: 100;
}
@include breakpoint (medium down){
  font-size: 30px;
  background: none;
  border: none;
  outline: none;
  margin-bottom: 5px;
  font-weight: 100;
}
@include breakpoint (small down){
  font-size: 18px;
}
}

.av-search-wrapper .search-form input[type=text]{
box-shadow: none;
height: 6.4375rem;

@include breakpoint (medium down){
  height: 4.4375rem;
}

@include breakpoint (small down){
  height: 3.4375rem;
}

&:focus{
  box-shadow: none;
}
}
  
.av-search-wrapper .search-form .av-search-button {
    float: right;
    display: block;
    margin-top: 45px;
    width: 40px;
    cursor: pointer;

    @include breakpoint (medium down){
      width:32px;
      margin-top: 22px;
  }

    @include breakpoint (small down){
    display:none;
    }
}

.av-search-wrapper .search-form .av-search-button svg {
    width: 70px;
}


.av-search-wrapper.av-opened-search {
  opacity: 1;
}
.av-search-wrapper.av-show-search {
  display: block;
}

.av-search-wrapper {
  position: fixed;
  display: none;
  opacity: 0;
  transition: opacity 0.5s;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $black-alpha;

}


.av-highlights-wrapper{
  padding: 50px 15px;
  @include breakpoint (small down){
	overflow: hidden;
  padding: 15px;
  }

	.av-highlights-title{
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 200;
		margin-bottom: 30px;
    letter-spacing: 2px;
    width: 20%;
    margin-right: 5%;
    float:left;

    @include breakpoint (medium down){
      float: left;
			font-size: 16px;
			width: 22%;
      margin-right: 8%;
    }
    
    @include breakpoint (small down){
      float: left;
			font-size: 16px;
			width: 50%;
      margin-right: 8%;
    }
    
	}
	.av-highlights-voices{
    float: left;
    width: 75%;
    @include breakpoint (medium down){
      float: left;
      width: 70%;
    }
    }

		li{
			margin-bottom: 15px;
			a{
				font-size: 18px;
				font-weight: 200;
			}
		}
  
}
