//Subscribe
.list {
    display: flex;
    flex-wrap: wrap;
}

.list-item {
    display: flex;
    padding: 0.5em;
    width: 100%;
}

.list-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.list-content p {
    flex: 1 0 auto;
}

.label-subscribe {
    position: absolute;
    top: 22%;
    text-align: center;
    width: 100%;
    h2 {
        font-size: 32px;
        color: $white;
        text-shadow: 0px 2px 5px $black;
    }

    h3{
        font-size: 24px;
        color: $white;
        text-shadow: 0px 2px 5px $black;
    }

    &.second {
        top: 29%;
    }

    p{
        text-shadow: 0 2px 5px $black;
        color: $white;
        margin: 20px 28px;
        font-size: 18px;
    }
}


//slider

#img-slider {

    opacity: 1;
    transition: 1s;
    .slick-dots li button:before {
        color: $black;
        font-size: 10px;
        line-height: 30px;
    }

    .slick-dots li.slick-active button:before {
        color: $gold;
    }

    .slick-next:before,
    .slick-prev:before {
        color: $gold;
        font-size: 40px;
    }

    .slick-prev {
        left: 40px;
        height: 40px;
        width: 40px;
        z-index: 1;
        @include breakpoint (medium down) {
           display: none!important;
        }
    }

    .slick-next {
        right: 60px;
        height: 40px;
        width: 40px;
        z-index: 1;
        @include breakpoint (medium down) {
            display: none!important;
         }
    }

}
.sample-slick {
    margin-right: 30px;
    .slick-next {
        right: -22px;
    }
    .slick-prev {
        display: none !important;
    }
}


.featured-img-text {
    display: flex;
    align-items: center;
    @include mb-8;
  
    p {
      font-size: 18px;
      text-transform: uppercase;
      @include inter-semibold;
      margin-bottom: 0;
      @include ml-8;
    }
  
    img {
      width: 60px;
      height: 60px;
    }

    &.dropshipping {
      justify-content: center;
    }
  }
  
  #best-brands {
    img {
      padding: 0 32px;
      @include breakpoint(medium down) {
        padding: 0 16px;
       }
    }
    &.slick-initialized.slick-slider {
      button.slick-prev:before {
        content: url(/skins/griffati/images/icons/arrow-left.svg);
        @include breakpoint(medium down) {
         display: none;
        }
      }
      
      button.slick-next:before {
        content: url(/skins/griffati/images/icons/arrow-right.svg);
        @include breakpoint(medium down) {
          display: none;
         }
      }
    }
  
    .slick-next, .slick-prev {
      width: 16px;
      top: 39%;
    }
    .slick-next {
      right: -11px;
    }
  
    .slick-prev {
      left: -11px;
    }
  }
  

  .div-text-slider {
    position: absolute;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    left: 100px;
    span {
      color: $gold;
      @include inter-semibold;
    }

    h1 {
      line-height: 72px;
      @include inter-bold;
    }

    @include breakpoint(medium only)  { 
      left: 50px;
        h1 {
          font-size: 28px;
          line-height: 40px;
        }
    }
      @include breakpoint(small down)  { 
        left: 10px;
        bottom: 10px;
        h1 {
          font-size: 18px;
          line-height: 24px;
        }
    }

  }

  .small-title-slider-home {
    text-transform: uppercase;
    @include inter-semibold; 

      @include breakpoint(small down)  { 
        font-size: 14px;
    }

  }

  .slider-box-right {
    position: absolute;
    right: 0;
    bottom: 100px;

    @include breakpoint(medium only)  { 
        bottom: 50px;
    }
    @include breakpoint(small down)  { 
      display: none;
    }

    h2 {
      font-size: 30px;
      line-height: 40px;
      @media screen and (min-width: 63.9375em) and (max-width: 87.5em) {
        font-size: 20px;
        line-height: 24px;
      }
      @include breakpoint(medium only)  { 
        font-size: 20px;
        line-height: 24px;
      }

    }

  }

  .slider-box-right-bg {
    background-color: $black;
    height: 72px;
    width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 64em) and (max-width: 87.5em)  { 
      width: 400px;
      height: 50px;
  }

    @include breakpoint(medium down)  { 
      width: 400px;
      height: 50px;
    }
  }
  .slider-box-right-text {
    h4, p, strong {
      color: $white;
      @media screen and (min-width: 64em) and (max-width: 87.5em)  { 
        font-size: 18px;
      } 
    }

    @include breakpoint(medium down)  { 
      h4 {
      font-size: 18px;
      }
    }
  }

  .bg-cms-row{
    background-color: $light-grey;
    padding: 64px 128px;

    @media screen and (min-width: 64em) and (max-width: 87.5em)  { 
        padding: 16px;
    }

      @include breakpoint(small down)  { 
      padding: 16px;
    }
  }
  .banner-country {
      h2, h4 {
        color: $white;
          @include breakpoint(small down) {
            font-size: 24px;
          }
      }
      img {
      width: 96px;
    }
  }
  
  .banner-solutions {
    display: flex;
    align-items: center;
    h4 {
      text-transform: uppercase;
      @include inter-medium;
    }
    p {
      color: $grey-2;
      font-size: 18px;
      @include inter-semibold;
    }
  
    span {
      @include inter-semibold;
      
    }
    img {
      width: 96px;
      height: 96px;

      &.review {
        border-radius: 50%;
      }
    }
  }
  
  .our-solutions {
    li {
      display: flex;
      align-items: center;
      @include mb-16;
      text-transform: uppercase;
      font-size: 14px;
      p {
        @include inter-semibold;
      }
      a {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        }
      &.language {
        @include mb-8;
      }
    }
  
    p {
      margin-bottom: 0;
    }
  }

  .image-solutions {
      display: flex;
      align-items: center;
      justify-content: center;

      &.right {
        justify-content: flex-end;
      }
  }

  //slider home

  #main-slider {
    width: 100%!important;
    height: 600px;
    @include breakpoint(medium only) {
      height:400px;
    }

    @include breakpoint(small down) {
      height:480px;
    }
}

.main-slider-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    h1 {
       @include mb-32;
        margin-top: 0;
        @include breakpoint(small down) {
            font-size: 40px;
            line-height: 48px;
        }
    }

    @include breakpoint(small down) {
      margin-top: 242px;
  }
}

.main-slider-text-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 90%;

    &.txt-left {
        justify-content: flex-start;
        margin-left: 56px;
        @include breakpoint(small down) {
         margin-left: 16px;
      }
    }

    &.txt-center {
        justify-content: center;
    }
    @include breakpoint(small down) {
        max-width: 100%;
    }
}

.swiper-slide {
    &.single-image{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left top;
        @include breakpoint(medium down) {
            background-position: right top;
        }
    }
    &.main-slider-home {
      @include breakpoint(small down) {
      margin-bottom: 230px;
      height: 250px;
      }
    }
}

.swiper-pagination-bullet {
    background-color: $black;
}
