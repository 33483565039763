/** initial setup -- nanoscroller default **/
.nano {
  position : relative;
  width    : 100%;
  height   : 100%;
  overflow : hidden;
}

.nano > .nano-content {
  position      : absolute;
  overflow      : scroll;
  overflow-x    : hidden;
  top           : 0;
  right         : 0;
  bottom        : 0;
  left          : 0;
  height        : 260px;
}
.nano > .nano-content:focus {
  outline: thin dotted;
}
.nano > .nano-content::-webkit-scrollbar {
  display: none;
}
.has-scrollbar > .nano-content::-webkit-scrollbar {
  display: block;
}
.nano > .nano-pane {
  background : rgba(0,0,0,.25);
  position   : absolute;
  width      : 7px;
  right      : 0;
  top        : 40px;
  bottom     : 0;
  visibility : hidden\9; /* Target only IE7 and IE8 with this hack */
  opacity    : .01;
  -webkit-transition    : .2s;
  -moz-transition       : .2s;
  -o-transition         : .2s;
  transition            : .2s;
  -moz-border-radius    : 5px;
  -webkit-border-radius : 5px;
  border-radius         : 5px;
   z-index: 3;
}
.nano > .nano-pane > .nano-slider {
  background: #f8f8f8;
  position              : relative;
  margin                : 0 1px;
  -moz-border-radius    : 3px;
  -webkit-border-radius : 3px;
  border-radius         : 3px;
}
.nano:hover > .nano-pane, .nano-pane.active, .nano-pane.flashed {
  visibility : visible\9; /* Target only IE7 and IE8 with this hack */
  opacity    : 0.99;
}

.nano:hover:after {
    content: ' ';
    background-color: #fff;
    width: 10px;
    position: absolute;
    right: 0;
    top: 40;
    bottom: 0;
    z-index: 2;
}

/* customization */

.nano {
  .nano-content {
    position: relative;
    padding-right: 10px;
  }

  .nano-pane {
    background: #e0e0e0;
    width: 5px;
    border-radius: 0;
  }

  .nano-slider {
    background: #aaa;
    margin: 0;
    border-radius: 0;
  }
}
