
.section-popup {
  display: flex;
  justify-content: center;
}

.popup {
  width: 80%;
  position: fixed;

  @media screen and (max-width: 39.9375em) {

    top: 150px;

  }

  @media screen and (min-width: 40em) and (max-width: 63.9375em) {

    top: 120px;

  }

  @media screen and (min-width: 64em) {

    top: 170px;

  }

  z-index: 100;
}
  .dot {
    display: inline-block;
    border: 2px solid $gold;
    width: 24px;
    height: 24px;
    /*background: white;*/
    border-radius: 30px;
    z-index: 999;
    cursor: pointer;
    content: '';
    color: transparent;
    font-size: 14px;
    line-height: 10px;
    vertical-align: middle;
    margin: 5px 10px 8px 0;
    font-weight: 300;
  }

[type='text'], [type='email'], [type='password'], textarea{
  box-shadow: none;
  border: 1px solid $grey-2;
  border-radius: 3px;
  color: $black;

  &.quantity-container{
    border: 1px solid $grey-2;
    border-radius: 0px;
  }

  &:focus{
    border: 1px solid $gold;
    box-shadow: none;
  }

}
