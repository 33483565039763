#product-slider-nav .slick-slide {
    padding: 0 8px;
}

.product-container {
    margin: 32px 0 32px 0;
    @include breakpoint(small down) {
        margin: 16px 0;
        padding-bottom: 54px;
    }
}

.slider-wrapper {
    @include mr-48;
    @include breakpoint(medium down) {
        margin-right: 0;
    }
}

.sample-slick-item {
    width: 100px !important;
    height: 100px !important;
    display: flex !important;
    position: relative;
    align-items: flex-end;
}

.product-slider {
    @include mb-8;
}

.delete-icon {
    color: $red;
    position: absolute;
    right: 3px;
    top: 4px;
    transform: scale(1.4);
    cursor: pointer;
}

#product-slider .slick-track .slick-current img {
    border: 5px solid $grey-3;
    @include pb-8;
}

#product-slider {
    .slick-prev.slick-arrow .slick-prev:before {
        content: url(/skins/current-skin/images/icons/arrow-grey-slider-left) !important;
    }
}

//slider

#product-slider {
    .slick-center {
        opacity: 1;
    }

    .slick-dots li button:before {
        color: $black;
        font-size: 10px;
        line-height: 30px;
    }

    .slick-dots li.slick-active button:before {
        color: $gold;
    }

    .slick-next:before,
    .slick-prev:before {
        color: $gold;
        font-size: 40px;
    }

    .slick-prev {
        left: 12px;
        height: 40px;
        width: 40px;
        z-index: 1;
    }

    .slick-next {
        right: 12px;
        height: 40px;
        width: 40px;
        z-index: 1;
    }

    .slick-prev:before {
        content: url(/skins/current-skin/images/icons/arrow-grey-slider-right.svg);
    }

    .slick-next:before {
        content: url(/skins/current-skin/images/icons/arrow-grey-slider-left.svg);
    }
}

.nav-slide {
    @include pb-8;
}

.list-tag {
    display: flex;
    flex-wrap: wrap;
    @include breakpoint(small down) {
        @include mt-24;
    }

    li {
        margin: 0 4px;
    }

    a {
        @include inter-bold;
        @include transition-basic;
        color: $dark-grey;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        border: 1px solid $grey-2;
        border-radius: 4px;
        padding: 4px 8px;

        &:hover {
            color: $black;
            border-color: $black;
        }
    }
}

.sample-image {
    &:hover {
        box-shadow: 1px 2px 12px rgba(49, 49, 49, 0.51);
    }
}

.product-info__title {
    @include inter-regular;
    font-size: 36px;
    margin: 14px 0;
    @include breakpoint(small down) {
        font-size: 28px;
    }
}

.product-info__subtitle {
    text-transform: uppercase;
    font-size: 15px;
    padding-bottom: 10px;
    letter-spacing: 0.05em;
    @include breakpoint(small down) {
        font-size: 11px;
    }
}

.product-info__descr {
    font-size: 14px;

    b {
        color: $dark-grey;
    }
}

.product-info__price {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 15px;
}

.product-info__single-price {
    text-align: right;

    > div {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    span {
        font-size: 80px;
        line-height: 72px;
        @include inter-bold;

        @include breakpoint(small down) {
            font-size: 50px;
            line-height: 56px;
        }
    }

    .discount span {
        font-size: 40px;

        @include breakpoint(small down) {
            font-size: 28px;
        }
    }
}

.product-info__size-table {
    text-align: center;
    margin: 20px 0 45px;

    a {
        @include inter-bold;
        @include transition-basic;
        color: $dark-grey;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        border: 1px solid $grey-2;
        padding: 5px 15px 3px;

        &:hover {
            color: $black;
            border-color: $black;
        }
    }
}

.i-number-btn {
    cursor: pointer;
    display: flex;
    min-width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;

    img {
        width: 14px;
    }
}

.qty-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.i-number {
    margin: 0;
    height: 35px;
    box-shadow: none;
    width: 70px;
    text-align: center;
    border: none;
    background-color: $white;
    cursor: default;
}

.qty-table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    th {
        @include inter-medium;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.05em;
        padding-bottom: 5px;
        padding-top: 5px;
    }

    td {
        @include inter-medium;
        border-top: 1px solid $grey-2;
        /* border-bottom: 1px solid $grey-2; */
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
        padding: 8px 0px;
        -ms-flex-align: center;
        align-items: center;
    }

    .availability {
        opacity: 0.4;
    }
}

.qty-max {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border: 1px solid $black;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 1px;
    margin-left: 8px;
    position: relative;

    &:active {
        top: 1px;
    }
}

.qty-table-resume {
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    button {
        @include inter-bold;
        background-color: $black;
        color: white;
        width: 182px;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.1em;
        padding: 9px 0 7px;
        position: relative;

        &:active {
            top: 1px;
        }
    }

    span {
        @include inter-bold;
        display: block;
        text-transform: uppercase;
        letter-spacing: 0.05em;

        &:first-child {
            font-size: 11px;
            padding-top: 5px;
        }
    }

    td {
        border-bottom: 1px solid $grey-2;
        padding: 25px 0;

        &:first-child {
            opacity: 0.4;
        }
    }
}
.table-sizes {
    border-collapse: collapse;
    td {
        &.table-cell {
            text-align: center;
            padding: 4px;
            border-bottom: 1px solid $light-grey;
            font-size: 14px;
            width: 25%;
            &.profile-orders {
                width: 0%;
            }
        }
    }
    th {
        font-size: 11px;
        text-transform: uppercase;
        @include inter-semibold;
        text-align: center;
    }
}
.size-col-1 {
    width: 25%;
}

.size-col-2 {
    width: 25%;
}

.size-col-3 {
    width: 50%;
}

.price-total {
    text-align: center;
    padding: 18px 0 12px;

    span {
        @include inter-bold;
        display: block;
        text-transform: uppercase;
        letter-spacing: 0.05em;

        &:first-child {
            font-size: 11px;
            margin-bottom: -6px;
        }

        &:last-child {
            font-size: 22px;
        }
    }
}

.related-title {
    text-align: center;
    color: $black;
    margin: 60px 0 40px;
    padding: 0 0 10px;
    @include breakpoint(small down) {
        font-size: 30px;
        margin: 50px 0 30px;
        padding: 0 0 6px;
    }
}

.list-colors-wrapper {
    text-align: center;
    border-bottom: 1px solid $grey-2;
    padding: 25px 0 28px;

    p {
        @include inter-bold;
        text-transform: uppercase;
        font-size: 11px;
        margin-bottom: -2px;
        @include breakpoint(small down) {
            margin-bottom: 10px;
        }
    }
}

.list-colors {
    li {
        display: inline-block;
        margin: 0 8px;
    }

    span {
        @include transition-basic;
        width: 58px;
        height: 5px;
        display: inline-block;
        @include breakpoint(small down) {
            width: 40px;
            height: 8px;
        }
    }

    a:hover,
    a.active {
        span {
            transform: scaleY(2.8);
        }
    }
}

.code-product {
    font-size: 15px;

    &.expanded {
        padding-bottom: 0px;
        margin-bottom: 10px;
    }
}

.gocheckout-sample {
    display: flex;
    justify-content: center;
}

.product-name-code {
    border-bottom: 1px solid $gold;
    padding-bottom: 10px;
}

.sample-empty {
    background: #d3d3d347;
    height: 90px;
    width: 100px;
}

.close-sample {
    position: absolute;
    top: 10px;
    right: 16px;
    cursor: pointer;
    width: 20px;
}

//slider

.product-slider {
    .slick-center {
        opacity: 1;
    }

    .slick-dots li button:before {
        color: $black;
        font-size: 10px;
        line-height: 30px;
    }

    .slick-dots li.slick-active button:before {
        color: $gold;
    }

    .slick-next:before,
    .slick-prev:before {
        font-size: 40px;
    }

    .slick-prev {
        left: 12px;
        height: 40px;
        width: 40px;
        z-index: 1;
    }

    .slick-next {
        right: 12px;
        height: 40px;
        width: 40px;
        z-index: 1;
    }

    .slick-prev:before {
        content: url(/skins/current-skin/images/icons/arrow-grey-slider-left.svg);
    }

    .slick-next:before {
        content: url(/skins/current-skin/images/icons/arrow-grey-slider-right.svg);
    }
}

.product-details {
    @include breakpoint(small down) {
        font-size: 16px;
    }
    .detail.-right {
        @include inter-medium;
        font-size: 18px;
        line-height: 30px;
        @include breakpoint(small down) {
            font-size: 16px;
        }
    }

    .detail p {
        margin-bottom: 2px;
        font-size: 18px;
        line-height: 29px;
        @include breakpoint(small down) {
            font-size: 16px;
        }
    }
}

.add-wishlist {
    border-top: 1px solid $gold;
    padding-top: 25px;
}

.quantity-cart {
    padding: 0px 18px;
}
//color swatch

.product-colors {
    li a {
        display: block;
        background-position: 50%;
    }
}

.product-colors {
    margin: 0 0 12px;
    padding: 0 0 14px;
    list-style: none;
    border-bottom: 1px solid $gold;

    li {
        display: inline-block;
        padding: 2px;
        width: 13.692%;
        cursor: pointer;

        a {
            display: block;
            height: 45px;
            background-position: center center;
            cursor: pointer;
        }

        &.active a {
            box-shadow: inset 0 0 0 2px $gold;
        }
    }
}

.img-wishlist {
    margin: 0px 0px 4px 4px;
}

//banner campione
.container_banner-campione {
    background-color: $light-grey;
    padding: 19px 28px;
    @include breakpoint(small down) {
        padding: 19px 19px;
    }

    &.home {
        padding: 50px 28px;
    }
}
.title-banner-container {
    font-size: 26px;
    margin-bottom: 15px;
}
.description-banner-container {
    font-size: 18px;
    margin-top: 20px;
}
.banner-campione {
    padding: 17px;

    &.home {
        padding-top: 0px;
    }
}

.container_banner-image {
    background-image: url(/skins/current-skin/images/product/tessuto-popup.jpg);
    background-position: center center;
}

.color-title {
    border: none;
    color: $black;
    font-size: 20px;
    padding: 1rem 0;
    font-style: normal;
    font-weight: 300;
}

.size-product {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        padding: 2px;
        width: 32%;

        @include breakpoint(small down) {
            width: 32%;
        }

        input[type="radio"] + label {
            background-color: $light-grey;
            border: 1px solid $light-grey;
            padding: 8px 0 6px;
            font-size: 14px;
            margin: 0;
            font-weight: 700;
            transition: 150ms;
            width: 100%;
            text-align: center;
            line-height: 1.8;
        }

        input {
            display: none;
        }

        input[type="radio"]:checked + label,
        input[type="radio"] + label:hover {
            background-color: $gold;
            color: $white;
        }

        span {
            background-color: $light-grey;
            color: #989898;
            padding: 8px 0 6px;
            font-size: 14px;
            font-weight: 700;
            width: 100%;
            text-align: center;
            display: inline-block;
            cursor: default;
            line-height: 1.8;
        }
    }
}

.title-size {
    font-size: 20px;
    margin-top: 15px;
}
.etichette-uso {
    height: 35px;
}

.detail > p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.div-already-wishlist {
    margin-top: 0px;
}

.add-wishlist {
    border-top: 1px solid $gold;
    padding-top: 25px;
}

.add-to-wishlist {
    margin-top: 0px;
}

.img-wishlist {
    margin: 0px 0px 4px 4px;
}

.color-swatch {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 24px;
    flex-wrap: wrap;

    .color {
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }

    .color-option {
        margin-left: 16px;
        border-radius: 50%;
        padding: 2px;
    }

    .color-option.selected {
        border: 1px solid $black;
    }

    .color-option:not(.selected) {
        border: 1px solid rgba($color: $black, $alpha: 0);

        &:hover,
        &:active {
            border: 1px solid $black;
        }
    }
}

.price-line-through {
    text-decoration: line-through;
    color: $grey-2;
}
