.checkout-wrapper {
  margin: 95px 0 70px;
}

.checkout-title {
  @include inter-regular;
}

.checkout-active {
  border-bottom: 1px solid $grey-2;
  padding: 15px 0 7px;
  margin: 0 0 35px;
  color: $black;
  font-size: 30px;
}

.checkout-step {
  padding: 16px 0 16px 24px;
  position: relative;
  color: $grey-2;
  font-size: 25px;
  @include inter-regular;

    &.active {
      color: $black;
      @include inter-medium;
    }

    &::before {
      color: $black;
      @include inter-medium;
      background-color: $white;
      position: absolute;
      left: 0;
      top: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 24px;
      color: $grey-2;
    }

    &.first::before {
      content: "1";
    }
    &.second::before {
      content: "2";
    }
    &.third::before {
      content: "3";
    }

    &.active::before {
      color: $black;
    }
}

.checkout-buttons {
  margin: 0 0 40px;

    .btn {
      @include breakpoint(small down) {
        margin-bottom: 10px;
      }
    }
}

.payment-table {
  display: flex;
  padding-bottom: 20px;
}

.payment-table {
  p {
    margin-bottom: 4px;
  }
}

p.orders-details {
  font-size: 14px;
  margin-bottom: 6px;

}

.next-step-checkout {
  border: 1px solid $grey-3;
}

.checkout-border {
  border: 1px solid $grey-3;
}

.border-paypal {
  border: 1px solid $gold;
  min-height: 47px;
}

.payment-method-icon {
  height: 40px;

  &.paypal {
    height: 47px;
  }

  &.credit-card {
    height: 35px;
  }
}


.credit-transfer {
  font-size: 14px;
  line-height: 24px;
  @include inter-medium;
  text-align: center;
}

.reveal {
  &.checkout {
    @include breakpoint (small down) {
      height: 300px;
      min-height: 300px;
      }
  }
}

.paypal-container {
  position: relative;

  &:hover {
    #fake-paypalBraintreeButton {
      color: $gold;
      box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.05);
    }
  }
}

#fake-paypalBraintreeButton {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;

  img {
    height: 21px;
  }

  .paypal-fee {
    color: $red;
    font-weight: bold;
    text-transform: lowercase;
    margin-left: 8px;
    font-size: 12px;
    margin-top: 2px;
  }

  .paypal-fee .euro {
    font-weight: bold;
  }
}