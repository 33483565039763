*,
body {
    @include inter-regular;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: $black;
    text-decoration: none;
    list-style-type: none;

    &:focus {
        outline: none;
    }
}

.body-container {
    padding-top: 131px;
    // overflow-x: hidden;
    min-height: 72vh;

    @media screen and (max-width: 718px) {
        padding-top: 59px;
    }

    @media screen and (min-width: 719px) and (max-width: 1024px) {
        padding-top: 99px;
    }

    @media screen and (max-height: 784px) {
        padding-top: 99px;
    }

    @media screen and (max-height: 639px) {
        padding-top: 59px;
    }

    &.product-reveal-popup {
        padding-top: 0 !important;

        @include breakpoint(small down) {
            padding-top: 40px !important;
        }
    }
}

.no-scroll {
    overflow: hidden;
}

.row {
    max-width: 110rem;

    &.centered {
        max-width: 80rem;
    }
}

.margin-header {
    @media screen and (max-width: 39.9375em) {
        margin-top: 0px;
    }
    @media screen and (min-width: 40em) {
        margin-top: 0px;
    }
}

.float-right {
    float: right;
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

:after {
    box-sizing: border-box;
}

.relative {
    position: relative;
}

.overflow-hidden {
    overflow: hidden;
}

ul li {
    font-size: 16px;
    padding-bottom: 4px;
}
.list-unstyled {
    list-style: none;
}

.height-100 {
    height: 100%;
}

.width-100 {
    width: 100%;
}

.width-70 {
    width: 70%;
}

.width-24 {
    width: 24px;
    height: 24px;
}

.inline-block {
    display: inline-block;
}

//section
.margin-section {
    @include mt-64;
    @include mb-64;
}

.margin-section-title {
    @include mt-64;
    @include mb-32;
    &.product {
        @include breakpoint(small down) {
            @include mt-40;
        }
    }
}

.padding-section {
    @include pt-32;
    @include pb-32;
}
// transitions
.transition-in {
    opacity: 1 !important;
    transition: 1s;
}

.transition-out {
    opacity: 0 !important;
    transition: 1s;
}

// errors
.error-password {
    color: $white;
    background-color: $red;
}

.error {
    border-color: $red !important;
    background-color: $light-grey;
}

label.error {
    color: $red !important;
    background-color: transparent !important;
}

.cms-wrapper {
    padding-bottom: 50px;

    u {
        text-decoration: underline;
    }
}

//fonts and colors
.txt-white {
    color: $white;
}

.txt-bold {
    @include inter-semibold;
}

.txt-gold {
    color: $gold;
}

.txt-center {
    text-align: center;
}

.txt-right {
    text-align: right;
}

.txt-left {
    text-align: left;
}

.text-shadow-none {
    text-shadow: none;
}

.upper {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.lower {
    text-transform: lowercase;
}

.inter-medium {
    @include inter-medium;
}

.inter-semibold {
    @include inter-semibold;
}

//flex class
.align-items-center {
    align-items: center;
}

.align-items-flex-end {
    align-items: flex-end;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-stretch {
    align-items: stretch;
}

.align-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

//arrow
.arrow-link,
.arrow-link-left {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    position: relative;
    display: inline-block;
}

.arrow-link {
    &:after {
        content: ">";
        margin-left: 10px;
        right: -16px;
        top: 25px;
        font-size: 18px;
        color: $gold;

        &.left {
            content: "<";
        }
    }
}

.left-arrow {
    color: $gold;
    @include pr-8;
}

.new-arrivals-link {
    &:before {
        content: url(/skins/current-skin/images/icons/new-arrivals-black.svg);
        margin-right: 10px;
        box-sizing: border-box;
    }
}

button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
}

.breadcrumb {
    @include mt-16;
    @include mb-24;

    @include breakpoint(small down) {
        margin-bottom: 0;
    }

    ol {
        display: flex;
    }

    li {
        display: inline;

        a {
            font-family: Inter;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: $dark-grey;
            margin-right: 8px;
        }
    }

    li.after:after {
        content: ":";
        white-space: pre;
    }

    .fa {
        font-size: 12px;
        margin-right: 8px;
    }
}

// border
.border {
    border: 1px solid $grey-2;
}

.border-gold {
    border: 1px solid $gold;
}

.border-right {
    border-right: 1px solid $black;

    @include breakpoint(small down) {
        border-right: 0px;
    }

    @include breakpoint(medium down) {
        border-right: 0px;
    }
}

.border-bottom-grey {
    border-bottom: 1px solid $light-grey;
}

//background

.bg-dark-grey {
    background-color: $dark-grey;
}

.bg-grey {
    background-color: $light-grey;
    padding: 32px;
    &.dropshipping-table {
        @include breakpoint(small down) {
            padding: 32px 0px;
        }
    }
}

.bg-white {
    background-color: $white;
}

.bg-gradient {
    background: $light-grey; /* Old browsers */
    background: -moz-linear-gradient(
        top,
        #f7f7f7 0%,
        #f7f7f7 43%,
        #ffffff 43%,
        #ffffff 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        top,
        #f7f7f7 0%,
        #f7f7f7 43%,
        #ffffff 43%,
        #ffffff 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to bottom,
        #f7f7f7 0%,
        #f7f7f7 43%,
        #ffffff 43%,
        #ffffff 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

    @include breakpoint(medium down) {
        background: -moz-linear-gradient(
            top,
            #f7f7f7 0%,
            #f7f7f7 24%,
            #ffffff 24%,
            #ffffff 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            #f7f7f7 0%,
            #f7f7f7 24%,
            #ffffff 24%,
            #ffffff 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to bottom,
            #f7f7f7 0%,
            #f7f7f7 24%,
            #ffffff 24%,
            #ffffff 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    @include breakpoint(small down) {
        background: -moz-linear-gradient(
            top,
            #f7f7f7 0%,
            #f7f7f7 16%,
            #ffffff 16%,
            #ffffff 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            #f7f7f7 0%,
            #f7f7f7 16%,
            #ffffff 16%,
            #ffffff 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to bottom,
            #f7f7f7 0%,
            #f7f7f7 16%,
            #ffffff 16%,
            #ffffff 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
}

//small divisor

.line-100 {
    width: 70px;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid;
    border-color: $gold;
}
.line-150 {
    width: 150px;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid;
    border-color: $gold;
}

.line-200 {
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid;
    border-color: $gold;
    margin: 10px 0px 20px 0px;
}

.line-full {
    width: 100%;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid;
    border-color: $dark-grey;
}
.order-request {
    border: 1px solid $grey-3;
    @include mb-16;
}

// fix menu ancore

.cms-wrapper .anchor {
    top: -160px;
    left: 0;
    position: absolute;
    font-size: 0;
}

.flex {
    display: flex;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-column-reverse {
    display: flex;
    flex-direction: column-reverse;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-column-tablet {
    @include breakpoint(medium down) {
        display: flex;
        flex-direction: column;
    }
}

.align-start-tablet {
    @include breakpoint(medium down) {
        align-items: flex-start;
    }
}

//space

.margin-0-auto {
    margin: 0 auto;
}

//margin top

.mt-8 {
    @include mt-8;
}

.mt-16 {
    @include mt-16;
}

.mt-24 {
    @include mt-24;
}

.mt-32 {
    @include mt-32;
}

.mt-40 {
    @include mt-40;
}

.mt-16-mobile {
    @include breakpoint(small down) {
        @include mt-16;
    }
}

.mt-24-mobile {
    @include breakpoint(small down) {
        @include mt-24;
    }
}

.mt-24-tablet {
    @include breakpoint(medium down) {
        @include mt-24;
    }
}

.mt-56 {
    @include mt-56;
}

//margin bottom

.mb-8 {
    @include mb-8;
}

.mb-16 {
    @include mb-16;
}

.mb-32 {
    @include mb-32;
}

.mb-24 {
    @include mb-24;
}

.mb-40 {
    @include mb-40;
}

.mb-56 {
    @include mb-56;
}

.icon24 {
    width: 24px;
    height: 24px;
}

//margin-right

.mr-8 {
    @include mr-8;
}

.mr-16 {
    @include mr-16;
}

.mr-40 {
    @include mr-40;
}

//margin-left

.ml-8 {
    @include ml-8;
}

.ml-16 {
    @include ml-16;
}

.ml-24 {
    @include ml-24;
}

//padding

.padding-16 {
    @include padding-16;
}

.pt-8 {
    @include pt-8;
}

.pt-16 {
    @include pt-16;
}

.pt-56 {
    @include pt-56;
}

.general-input {
    border: 1px solid $light-grey;
}

.pagination-arrow {
    width: 8px;
}

.reveal-overlay {
    z-index: $menu-z - 1 !important;
    top: 131px !important;
    @media screen and (min-width: 40em) {
        background-color: hsla(0, 0%, 4%, 0.85) !important;
    }

    @media screen and (max-width: 718px) {
        top: 59px !important;
    }

    @media screen and (min-width: 719px) and (max-width: 1024px) {
        top: 99px !important;
    }

    @media screen and (max-height: 784px) {
        top: 99px !important;
    }

    @media screen and (max-height: 750px) {
        top: 59px !important;
    }
}

.reveal.language_currency {
    top: 75px !important;

    @include breakpoint(small down) {
        top: 48px !important;
        height: auto;
        padding-bottom: 56px;
    }
}

.fixed {
    position: fixed !important;
}

.fix-bug-marginleft {
    @media screen and (min-width: 40em) {
        margin-left: 48px;
    }
}

.px22 {
    width: 22px;
}

.swiper-product {
    overflow: hidden;
}
.swiper-product-pagination {
    .bullet {
        &.current {
            border: 8px solid $light-grey;

            @include breakpoint(medium down) {
                border: 4px solid $light-grey;
            }
        }
    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: white;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

.loading-master {
    background: $black;
    opacity: 0.6;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
}

input[type="checkbox"] + label.link-conditions {
    @include inter-semibold;
    a {
        @include inter-semibold;
    }
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

//chat
div#lhc_container {
    &.lhc-min {
        @include breakpoint(small down) {
            bottom: 62px !important;
        }
    }
}
