.content-404 {
  margin: 9vw 0 10vw;
  @include breakpoint(small down) {
    margin: 55px 0 70px;
  }

    h1 {
      @include inter-bold;
      font-size: 100px;
      line-height: 100px;
      letter-spacing: 0.05em;
      @include mt-16;
      @include mb-16;
    }

    h2 {
      font-size: 30px;
      letter-spacing: 0.01em;
    }

    p {
      margin: 3px 0 25px;
    }
}


.pagination a {
  @include inter-regular;
  transition: all .15s ease-in-out;
  border:none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.pagination a:hover {
  background-color: $gold;
  color: $white;
}

.pagination .active a, .pagination a:hover {
  border-radius: 50%;
  background-color: $gold;
  color: $white;
}

.magazine-title {
  @include mt-32;
  @include mb-32;

  @include breakpoint(small down) {
    margin: 0;
  }

  h1 {
    text-transform: uppercase;
    font-size: 34px;
    line-height: 40px;
    @include inter-medium;
    @include breakpoint(small down) {
      font-size: 28px;
    }
    span {
      font-size: 34px;
      line-height: 40px;
      @include inter-semibold;
      @include breakpoint(small down) {
        font-size: 28px;
      }
    }
  }
}

.main-title {
  text-align: center;
    &.left {
      text-align: left;
    }

    &.two-row-small {
      @include breakpoint(small down) {
      padding: 0 32px;
    }
      }

    h2 {
      text-transform: uppercase;
      @include inter-medium;
      line-height: 40px;
      @include breakpoint(small down) {
        font-size: 28px;
      }
      span {
        font-size: 34px;
        @include inter-semibold;
        @include breakpoint(small down) {
          font-size: 28px;
        }
      }
   }
   h1 {
    text-transform: uppercase;
    font-size: 34px;
    line-height: 40px;
    @include inter-medium;
    @include breakpoint(small down) {
      font-size: 28px;
    }
    span {
      font-size: 34px;
      line-height: 40px;
      @include inter-semibold;
      @include breakpoint(small down) {
        font-size: 28px;
      }
    }
  }
  &.designer {
    h1 {
      font-size: 34px;
      line-height: 40px;
      @include inter-medium;
    }
    span {
    font-size: 34px;
    line-height: 40px;
    @include inter-semibold;
    }
  }
}

//dropshipping page

.div-title-dropshipping {
  text-align: center;
  border-bottom: 1px solid $light-grey;
  p {
    font-size: 18px;
    @include inter-medium;
    color: $dark-grey;
  }
}

.dropshipping-price {
  background-color: $black;
  text-align: center;
  @include pb-16;
  @include pt-16;

  &.bg-grey {
    background-color: $light-grey;
  }
}

.price-dropshipping {
  color: $white;
  text-align: right;
  @include inter-medium;
  &.black {
    color: $black;
  }
  &.page {
    font-size: 25px;
    line-height: 32px;
    @include inter-semibold;
    color: $black;
  }
}

.subscription-total-price {
  display: flex;
  align-items: flex-start;
  background-color: $grey-3;
  padding: 16px;
}

.dropship-vat {
  font-size: 25px;
  line-height: 32px;
  color: $black;
  @include inter-semibold;

  &.vat {
    @include inter-regular;
    font-size: 18px;
    line-height: 24px;
  }
}

.support-include {
  p{
      font-size: 14px;
      line-height: 24px;
      @include inter-medium;
  }
}
div.support-include {
    font-size: 14px;
    line-height: 24px;
    @include inter-medium;
}

.total-dropshipping {
  span {
    @include inter-semibold;
  }
}

.subscription-price {
  span, span.euro {
    @include inter-semibold;
  }
}

.border-subscription {
  @include mt-16;
  border: 1px solid $light-grey;
}

.title-subscriptions {
  font-size: 34px;
  line-height: 40px;
  @include mt-56;
  @include mb-56;
}

.dropship-list {
  border-bottom: 1px solid $grey-3;
  p {
    font-size: 20px;
    line-height: 24px;
    @include inter-medium;
  }
}

.subscription-description {
  min-height: 427px;
  background-color: $light-grey;
  padding: 32px;
}


.border-bottom-grey-dropshipping {
  border-bottom: 1px solid $light-grey;
  
  p {
   @include mt-8;
  }
}

.border-dropshipping-blocks {
  border: 1px solid $grey-2;
  padding: 16px 16px 0 16px;
}

.modules-dropshipping-price {
    font-size: 30px;
    line-height: 40px;
    @include inter-bold;
    a {
      color: $gold;
    }

  &.table {
    font-size: 30px;
    line-height: 40px;
    .grey {
      color: $grey-2;
      font-size: 14px;
    }
  }

  span {
    color: $grey-2;
    font-size: 14px;
    @include inter-medium;
  }

}

.modules-price-subtitle {
  color: $grey-2;
  font-size: 14px;
  @include inter-medium;
}

.div-dropshipping-tabs {
  padding: 16px 72px 0 72px;
  @include breakpoint(small down) {
    padding: 32px;
  }
}

.dropshipping-tabs {
  .tabs-content {
    background-color: transparent;
    border: none;
  }

  .tabs {
    background-color: $grey-3;
    border: none;
    border-radius: 8px;
  }
  .tabs-title>a:focus, .tabs-title>a:hover, .tabs-title>a[aria-selected=true] {
    border: 1px solid $gold;
    border-radius: 8px;
    background: $gold-alpha;
    color: $dark-grey;
    @include inter-semibold;
  }
  .tabs-title>a {
    color: $dark-grey;
    font-size: 16px;
    text-align: center;
  }

  .table-title {
    width: 33%;
    padding-bottom: 0;
  }

  .tabs-title {
    width: 33%;
  }

  td {
    border: 1px solid $light-grey;
    padding: 16px;

    &.baseline {
      vertical-align: baseline;
    }
  }

  .tabs.row {
    .large-2 {
      flex: 0 0 20%;
      max-width: 20%;
        @include breakpoint(small down) {
        flex: 0 0 100%;
        max-width: 100%;
        }
    }

  }
}

.subtitle {
  color: $grey-2;
  font-size: 18px;
  @include inter-medium;
}

.table-bg {
  text-align: center;
  &.blue {
    background-color: $table-bg-blue;
    color: $white;
  }
  &.green {
    background-color: $table-bg-green;
    color: $white;
  }
  p {
    margin-bottom: 0px;
    color: $white;
  }
}
.subtitle-price {
  font-size: 12px;
  color: $grey-2;
  &.txt-gold {
    color: $black;
    text-transform: uppercase;
    font-size: 18px;
    @include inter-medium; 
    @include mt-8;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px; height: 0; overflow: hidden;
  }
  
  .video-container iframe,
  .video-container object,
  .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  }

  .subtitle-main-image {
    color: $grey-2;
  }
  
// new arrivals page
.main-box-banner-figure {
  display: flex;
  align-items: center;
  @include breakpoint(medium down) {
    display: block;
  }
}

.box-banner-figure {
  position: absolute;
  bottom: 2vw;
  left: 0vw;
  width: calc(100vw / 20 * 3);
  height: 22.5vw;
  height: 13.5vw;

  @include breakpoint(medium down) {
    top: 32vw;
    left: 9px;
    height: 50.5vw;
    width: calc(100vw / 12 * 4);
  }
  &.right {
    left: calc(100vw / 20 * 2);
    @include breakpoint(medium down) {
      left: calc(100vw / 20 * 11);
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.swiper-wrapper {
  align-items: center !important;
  transition-timing-function: linear;
}

.mt-parallax {
  margin-top: 5vw;
}

.parallax-img-description {
  text-align: center;
  p {
    color: $grey-2;
    @include inter-medium;
    @include mt-8;
  }
}

//table size

.table-size {
    th, p {
    text-align: center;
    }
    tr.firstrow{
      background-color: $grey-3;
    }
    tr.secondrow>th {
      width: 300px;
    }

    &.dropshipping {
      tr.firstrow{
        background-color: $gold;
        th {
          color: $white;
        }
      }
    }
}


.title-payment {
  font-size: 20px;
  line-height: 24px;
}

.home-hero__text {
 background-repeat: no-repeat;
 background-size:cover;
 background-position:center center; 
 height: 500px;
  @include breakpoint (medium down) {
    background-image: none!important;
  }
}

.section-border-left {
  border-left: 1px solid $grey-3;
  @include pl-32;
    
  @include breakpoint (medium down) {
    @include pl-16;
    border-left: none;
  }
}

.section-border {
  @include pr-32;
  @include breakpoint (medium down) {
    @include pl-16;
    @include pr-16;
    border-left: none;
  }
}

.img-about-us {
  filter: grey-2scale(100%);
}

.subtitle-text {
  h2 {
    font-size: 40px;
    line-height: 48px;
    
    @include breakpoint (medium only) {
      font-size: 18px;
      line-height: 24px;
    }

    @include breakpoint (small down) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.check {
  width: 15px;
  height: 11px;
}

.flag {
  width: 16px;
  height: 11px;
}

.dropshipping-icon-table {
  width: 16px;
  height: 16px;
}

//landing

.main-image-cms {
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 0;
  display: flex;
  @include breakpoint (medium only) {
    height: 400px
  }

  @include breakpoint (small down) {
    margin-bottom: 260px;
    height: 250px;
  }

  .subtitle-text {
    h2 {
      font-size: 30px;
      line-height: 40px;
      
      @include breakpoint (medium only) {
        font-size: 18px;
        line-height: 24px;
      }
  
      @include breakpoint (small down) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  
    h3 {
      font-size: 25px;
      line-height: 32px;
      @include breakpoint (medium only) {
        font-size: 18px;
        line-height: 24px;
      }
  
      @include breakpoint (small down) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

}

.four-image-grid {
  justify-content: center;
  border: 1px solid $light-grey;
}

.new-arrivals {
  overflow-x: hidden;
}


//blog
.smooting-corners-blog-1 {
  border-radius: 0px 50px;

}
.smooting-corners-blog-2 {
  margin-top: 13px;
  border-radius: 50px 0px;
}

.blog-post-text, .post-summary-container-archive {
  text-align:left;
  box-shadow: 0 2px 20px rgba(#b1b1b1, 0.102);

  &:hover {
    box-shadow: 0 2px 20px rgba(#b1b1b1, 0.4);
  }


  h1{
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  h2{
    font-size: 30px;
    line-height: 40px;

    &.related {
      font-size: 22px;
      line-height: 32px;
    }
  }

  h3{
    font-size: 24px;
    line-height: 32px;

    &.related {
      font-size: 22px;
      line-height: 32px;
    }
  }
}

.date {
  font-size: 14px;
  line-height: 24px;
  color: $grey-2;

  &.single-post {
    font-size: 16px;
    text-align: center;
  }
}

.post-summary-container-archive {
  .post-date {
    font-size: 14px;
    line-height: 24px;
    color: $grey-2;
    background-color: $white;
    @include pt-8;
    @include mt-8;
  }

  .related {
      font-size: 22px;
      line-height: 32px;
      @include breakpoint(small down) {
        max-width: 300px;
      }     
  }
  @include breakpoint(medium only) {
    @include mb-16;
  }

  @include breakpoint(small down) {
    @include mb-16;
  }
  .blog-post-image {
    @include breakpoint(medium down) {
      display: none;
    }
  }
  .description-blog {
    @include breakpoint(medium down) {
      display: none;
    }
  }

  .blog-post-image {
    height: 250px;
    overflow: hidden;
    border-bottom: 3px solid $gold;
    @include breakpoint(small down) {
      height: 110px;
    }

    div {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      height: inherit;
      transition: all .3s ease-out;
    }
  }

  .bg-post-text {
    background-color: $white;
    border-radius: 8px;
    @include breakpoint(small down) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  .blog-post-link {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  &.home {
    .blog-post-image {
      border-bottom: none;
      height: 300px;
      border-radius: 0 0 4px 4px;
    }
  }

  &.small {
    .blog-post-image {
      height: 130px;
      border-radius: 0 0 4px 4px;
    }
  }
}

.title-sumamry-container {
  padding: 8px 16px 16px 16px;
}

.last-post {
  box-shadow: 0 2px 20px rgba(#b1b1b1, 0.4); 
  border-radius: 8px;
}

.main-title-blog {
  font-size: 40px;
  line-height: 48px;
  text-align: left;

  @include breakpoint(small down) {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0;
  }
}

.main-blog-post-image {
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  border-radius: 0px 8px 8px 0px;
  @include breakpoint(small down) {
    height: 200px;
    @include mt-16;
  }

}

.last-post-left-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
  @include breakpoint(medium down) {
    @include mt-16;
  }

}


.title-article-blog {
  text-align: center;
  font-size: 34px;
  line-height: 40px;
}

//Dropshipping page
body {
    &.logged {
      .btn-logged {
        display: inline-block;
      }

      .btn-not-logged {
        display: none;
      }
      
      #subscribe-dropshipping {
        display: none;
      }
    }

    .btn-logged {
      display: none;
    }
  }

  //sync2fashion

  .faq-section {
    @include mb-16;
    @include mt-32;
  
    .faq {
      width: 100%;

      &.open {
        .toggle {
          background-color: $light-grey;
          @include inter-semibold;
        }
      }
  
      .toggle {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 16px;
        border-bottom: 1px solid $grey-3;
  
        img.open {
          display: block;
        }
        img.close {
          display: none;
        }
      }
  
      .question {
        font-size: 16px;
        line-height: 24px;
        @include inter-medium;
        width: 100%;
        @include pr-8;
      }
      
      .answer {
        display: none;
        width: 100%;
        padding: 24px 24px 16px 24px;
        border-bottom: 1px solid $grey-3;
      }
  
      &:first-child .toggle {
        border-top: 1px solid $grey-3;
      }
    }
  
    .faq.open .toggle {
      img.open {
        display: none;
      }
      img.close {
        display: block;
      }
    }
  }