@import "../fonts/Inter/Inter.css";

h1,
.title-1 {
    font-size: 50px;
    line-height: 56px;
}

h2,
.title-2 {
    font-size: 34px;
    line-height: 40px;
}

h3,
.title-3 {
    font-size: 30px;
    line-height: 40px;
}

h4,
.title-4 {
    font-size: 25px;
    line-height: 32px;
}

h5,
.title-5 {
    font-size: 18px;
    line-height: 24px;
}

p,
.body-1 {
    font-size: 16px;
    line-height: 24px;
}

h1 {
    font-weight: 700;
}
h2 {
    font-weight: 600;
}
h3,
h4,
h5 {
    font-weight: 500;
}

p {
    margin-bottom: 8px;
}

.regular {
    font-weight: 400;
}
.medium {
    font-weight: 500;
}
