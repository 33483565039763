.product-item {
  cursor: pointer;
  position: relative;
  text-align: center;
  border: 1px solid $light-grey;
  padding: 8px;

    &:hover {
      .product-item__img picture+picture {
        opacity: 1;
      }
      .size-singleproduct {
        opacity: 0.8;
      }
    }
}

.close-popup {
  position: absolute;
  right: 16px;
  width: 16px;
  cursor: pointer;

  &.wishlist {
    right: 48px;
    top: 30px;
  }
}


.product-item__img {
  cursor: pointer;
  position: relative;
  margin: 0 0 10px;

    picture+picture  {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: 1;
    }
}

.product-item__brand {
  @include inter-medium;
  font-size: 24px;
  margin-bottom: 2px;
  @include breakpoint(small down) {
    font-size: 18px;
  }
}

.product-item__descr {
  @include inter-bold;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.05em;
}

.price-catalog {
  display: flex;
  align-items: center;

  &.best-taxable {
    flex-direction: column;
    align-items: flex-start;
  }

  &.best-taxable-expanded {
    flex-direction: column;
    align-items: flex-start;
  }

  &.retailer {
    justify-content: flex-end;

    @include breakpoint(medium down) {
      justify-content: flex-start;
    }
  }
}

.unit-price, .unit-price span {
  font-size: 13px;
  text-align: left;

  @include breakpoint(small down) {
    font-size: 12px;
  }
}

.product-container {
  .unit-price, .unit-price span {
    font-size: 15px;
    text-align: left;
  }
}

.product-item__retail-price {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: $dark-grey;
  margin-top: -1px;
  display: block;
}
.product-item__price {
  font-size: 25px;
  @media screen and (min-width: 900px) and (max-width: 1024px) {
    font-size: 18px;
  }
  
  @include breakpoint(small down) {
    font-size: 18px;
  }
}
.product-item__price-block {
  cursor: pointer;
  &.list {
    display: flex;
    align-items: baseline;
  }

    .iva {
      margin-top: 10px;
      padding-bottom: 5px;
    }
  }

  span.discount{
    color: $dark-grey;
    font-size: 20px;
    margin-left: 10px;
    text-transform: inherit;

    &.linedthrough{
      text-decoration: line-through;
    }
  }

.product-item__link {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  z-index: 2;
}

.product-item__table {
  @include breakpoint(small down) {
    margin: 10px 0px;
  }
}

.product-item__description {
  border-top: 1px solid $grey-2;
  padding-top: 15px;
}

//catalog griglia

.prompt-delivery{
  border: 1px solid $gold;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 5px auto 0;
  
  &.expanded{
    margin: 5px 0px;
  }
  p{
    margin-bottom: 0px;
    text-transform: uppercase;
    font-size: 14px;
  }

  &.size{
    border: none;
    margin: 10px 0px 0;

    p {
      color: $gold;
      text-align: center;
    }
  }
}

span.prompt-delivery{
      color: $gold;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      margin-left: 5px;
}

.rate-price{
  .save{
    text-align: left;
    font-size: 18px;
    margin-top: 4px;
    color: $dark-grey;
    text-transform: inherit;
    letter-spacing: 0.3px;
  }
  .price{
    color: $dark-grey;
    font-size: 18px;
    margin-left: 10px;
  }
}

.product-item.relative {
  .add-to-wishlist-catalog {
    display: none;
    @include breakpoint(medium down) {
      display: block;
    }
  }
  &:hover {
    .add-to-wishlist-catalog {
      display: block;
    } 
  } 
}

.product_brand {
  font-size: 50px;
  line-height: 56px;
  @include inter-bold;

  @include breakpoint(small down) {
   font-size: 30px;
   line-height: 32px;
  }

  &.catalog {
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    margin-bottom:0;
    @include inter-regular;
  }
}

.product-code {
  font-size: 16px;
  color: $grey-2;
  @include mt-8;
}

.dl-product {
    dt {
      width: 50%;
      float: left;
      clear: left;
      text-align:left;
      @include inter-medium;
      font-size: 14px;
    }

  dd {
      width: 50%;
      float: left;
      margin: 0;
      text-align: right;
      font-size: 14px;
  }
}


.retailer-price {
  color: $grey-2;
  font-size: 25px;
  @include inter-medium;
  span {
    color: $grey-2;
    @include inter-medium;
  }

  &.catalog {
    font-size: 12px;
    @include breakpoint(small down) {
      font-size: 11px;
     }

    span {
      color: $grey-2;
      @include inter-medium;
    }
  }
  .euro {
    color: $grey-2;
    @include inter-medium;
  }

  @include breakpoint(small down) {
    font-size: 18px;
   }
}

.product-expanded-price {
  border-bottom: 1px solid $light-grey;
  @include mb-8;
  @include pb-8;
}

.vat {
  font-size: 14px;
  line-height: 16px;
  @include inter-regular;
}