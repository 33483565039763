.footer-logo {
    text-align:center;
  
    img{
      width: 100px;
    }
    
    p {
      font-size:18px;
      @include inter-regular;
      color: $black;
      }
}

.footer-icons {
  background-color: $black;
  text-align: center;
  padding: 20px 0 24px;
  @include breakpoint(small down) {
    padding: 0 0 40px;
  }

    span {
      color: $white;
      display: block;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: .15em;
      @include breakpoint(medium down) {
        font-size: 9px;
      }
    }

    .footer-icons__title {
      @include inter-bold;
      font-size: 13px;
    }

    img {
      width: 35px;
      margin-bottom: 5px;
      @include breakpoint(small down) {
        margin: 35px 0 5px;
      }
    }

    h5 {
      color: $white;
      font-size: 19px;
      margin: 12px 0 16px;
    }
}

.footer-payments {
  text-align: center;
  border-bottom: 1px solid $grey-2;
  padding: 30px 0 32px;
  @include breakpoint(medium down) {
    padding: 30px 0 28px;
  }

    h4 {
      @include inter-bold;
      letter-spacing: .15em;
      font-size: 13px;
      text-transform: uppercase;
      margin-bottom: 25px;
    }
}

.footer-payments__logos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  filter: grayscale(100%);

    img {
      margin: 0 10px;
      @include breakpoint(medium down) {
        margin: 0 8px 10px;
      }
    }
}

.footer-social {
  background-color: $black;
  padding: 28px 0 30px;
  text-align: center;

    h4 {
      @include inter-bold;
      letter-spacing: .15em;
      font-size: 13px;
      text-transform: uppercase;
      margin-bottom: 25px;
      color: $white;
    }

    img {
      width: auto;
      height: 25px;
      margin: 0 10px;
    }
}

.footer-social__flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-bottom {
  background-color:$black;
  @include pt-32;
  @include pb-32;
   
    p {
      color: $white;
    }
    ul{
      margin-top: 4px;
    }

    .title {
      @include inter-semibold;
      color: $white;
      margin-bottom: 4px;
    }

    .paragraph, li {
      font-size: 14px;
      color: $white;
    }

    li a {
    color: $white;
    text-align: left;
    font-size: 14px;
  }

  .mt-8 {
   margin-top: 4px; 
  }
}

.footer-data-space{
  text-align:center;
  font-size: 12px;
  margin-bottom: 0;
}

.footer-mobile-top-space {
  @include breakpoint(small down) {
    @include mt-16;
  }
}

.cookie_policy {
  text-align: center;
  padding: 11px 0 10px;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 12px;
  color:$white;
  

    a {
      @include inter-regular;
      
    }
}

.contact {
  img{
    margin-right:10px;
  }
}

.social-icons-footer {
  @include mt-32;
  text-align: center;

  img {
    @include mr-8;
    width: 32px;

    &.download {
      width: 80px;
      @include breakpoint(small down) {
        @include mt-16;
      }
    }
  }
}


.contact {
  img {
    margin-right:10px;
  }
}